import {
    ActivateTwoStepAuthenticationPayload,
    TwoStepAuthenticationResponsePayload,
} from './twoStepAuthentication.types';
import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import {
    getTwoStepAuthenticationActivationStatus,
    postActivateTwoStepAuthentication,
    postDeactivateTwoStepAuthentication,
    postResendTwoStepAuthenticationCode,
} from './twoStepAuthentication.api';

export const fetchTwoStepAuthenticationActivationStatus = createAsyncThunk<
    boolean,
    number,
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/TwoStepAuthentication/getTwoStepAuthenticationActivationStatus',
    async (bidderId, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await getTwoStepAuthenticationActivationStatus({
            authToken,
            bidderId,
            deployment,
        });
        if (error) {
            return rejectWithValue(new Error(payload as string));
        }
        return (payload as TwoStepAuthenticationResponsePayload).mfaEnabled;
    }
);

export const activateTwoStepAuthentication = createAsyncThunk<
    boolean,
    ActivateTwoStepAuthenticationPayload,
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/TwoStepAuthentication/activateTwoStepAuthentication',
    async ({ bidderId, populatedNewAccountAuthToken }, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await postActivateTwoStepAuthentication({
            authToken: populatedNewAccountAuthToken || authToken,
            bidderId,
            deployment,
        });
        if (error) {
            return rejectWithValue(new Error(payload as string));
        }
        return (payload as TwoStepAuthenticationResponsePayload).mfaEnabled;
    }
);

export const deactivateTwoStepAuthentication = createAsyncThunk<boolean, number, AsyncThunkConfigWithRejectValue>(
    'la/ui/TwoStepAuthentication/deactivateTwoStepAuthentication',
    async (bidderId, { getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const { error, payload } = await postDeactivateTwoStepAuthentication({
            authToken,
            bidderId,
            deployment,
        });
        if (error) {
            return rejectWithValue(new Error(payload as string));
        }
        return (payload as TwoStepAuthenticationResponsePayload).mfaEnabled;
    }
);

export const resendTwoStepAuthenticationCode = createAsyncThunk<void, string>(
    'la/ui/TwoStepAuthentication/resendTwoStepAuthenticationCode',
    async (emailAddress, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);

        return postResendTwoStepAuthenticationCode({
            deployment,
            emailAddress,
        });
    }
);
