import { TypedActionWithPayload } from '@/types/redux';

export const HYDRATE_CLIENT_STATE = 'HYDRATE_CLIENT_STATE';
export type HYDRATE_CLIENT_STATE_ACTION = TypedActionWithPayload<
    typeof HYDRATE_CLIENT_STATE,
    {
        dateLastShown: string;
        disableModalForTesting: boolean;
        hasLoggedInBefore: boolean;
    }
>;
