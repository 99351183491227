export type DeleteAccountSlice = {
    error: Error;
    submitted: boolean;
    success: boolean;
};

export const defaultDeleteAccountSlice: DeleteAccountSlice = {
    error: null,
    submitted: false,
    success: false,
};

export type SubmitDeleteAccountParams = {
    bidderId: number;
    token: string;
};
