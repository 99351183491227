import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makeGet, makePost } from '@/redux/api/helpers';
import {
    CodeDeliveryMethod,
    GetUserByTokenParams,
    GetUserByTokenResponsePayload,
    PostLoginParams,
    PostLoginResponse,
    PostTwoStepAuthenticationLoginParams,
} from '@/redux/modules/account/login/login.types';
import { ConfigSlice } from '@/redux/modules/config';

/**
 * POST to item-api service to authenticate and log in a user
 * @function postLogin
 * @category Item API
 * @param {PostLoginParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/auth/spalogin
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-spa-login.ts
 */
export const postLogin = ({ deployment, password, sessionId, username }: PostLoginParams) =>
    new Promise<PostLoginResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'auth/spalogin',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.type('form');
        request.send({ password, sessionId, username });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * POST to item-api service to verify user with two-step authentication code
 * @function postLoginWithTwoStepAuthentication
 * @category Item API
 * @param {PostTwoStepAuthenticationLoginParams}
 * @see https://item-api-deployment.liveauctioneers.com/auth/spalogin/mfa
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-mfa-login.ts
 */

/**
 * POST to item-api service to authenticate and log in a user
 * @function getUserByToken
 * @category Item API
 * @param {GetUserByTokenParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/auth/user
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-user.ts
 */
export const getUserByToken = ({ authToken, deployment }: GetUserByTokenParams) =>
    new Promise<GetUserByTokenResponsePayload>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'auth/spauser',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * POST to item-api service to verify user with two-step authentication code
 * @function postLoginWithTwoStepAuthentication
 * @category Item API
 * @param {PostTwoStepAuthenticationLoginParams}
 * @see https://item-api-deployment.liveauctioneers.com/auth/spalogin/mfa
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-mfa-login.ts
 */

export const postLoginWithTwoStepAuthentication = ({
    deployment,
    password,
    twoStepAuthenticationCode,
    username,
}: PostTwoStepAuthenticationLoginParams) =>
    new Promise<PostLoginResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'auth/spalogin/mfa',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.type('form');
        request.send({
            password,
            twoStepVerificationCode: twoStepAuthenticationCode,
            username,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

export type PostTwoStepAuthenticationPayload = {
    deployment: ConfigSlice['deployment'];
    emailOrUsername: string;
};

export type PostObfuscatedPhoneNumberResponse = {
    error: boolean;
    payload: string;
};

export const postFetchObfuscatedPhoneNumber = ({ deployment, emailOrUsername }: PostTwoStepAuthenticationPayload) =>
    new Promise<PostObfuscatedPhoneNumberResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'get-phone-number',
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({ usernameOrEmail: emailOrUsername });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

export type PostFetchBidderTokenByGoogleCredentialPayload = {
    deployment: ConfigSlice['deployment'];
    deviceId: string | undefined;
    googleCredential: string;
    pageName: string;
    sessionId: string | undefined;
    url: string;
    visitorCode: string;
};

export type PostFetchBidderTokenByGoogleCredentialResponse = {
    error: boolean;
    payload: { [key: string]: string };
};

export const postFetchBidderTokenByGoogleCredential = ({
    deployment,
    deviceId,
    googleCredential,
    pageName,
    sessionId,
    url,
    visitorCode,
}: PostFetchBidderTokenByGoogleCredentialPayload) =>
    new Promise<PostFetchBidderTokenByGoogleCredentialResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'auth/google/validate',
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({
            deviceId,
            pageName,
            sessionId,
            token: googleCredential,
            url,
            visitorCode,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

export type PostSendTwoStepAuthenticationCodePayload = {
    deliveryType: CodeDeliveryMethod;
    deployment: ConfigSlice['deployment'];
    emailOrUsername: string;
};

export const postSendTwoStepAuthentication = ({
    deliveryType,
    deployment,
    emailOrUsername,
}: PostSendTwoStepAuthenticationCodePayload) =>
    new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: 'mfa/send-otp',
            deployment,
            path: ApiHosts.Authentication,
        });
        request.send({
            deliveryType: deliveryType || CodeDeliveryMethod.Email,
            username: emailOrUsername,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

export type PostSubmitEmailVerificationCodePayload = {
    deployment: ConfigSlice['deployment'];
    otp: string;
    username: string;
};

export const postSubmitEmailVerificationCode = ({
    deployment,
    otp,
    username,
}: PostSubmitEmailVerificationCodePayload) =>
    new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: 'otp/validate',
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({
            otp,
            username,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
