import { autoOpenSignUpModal } from '@/redux/modules/autoPopModal/autoPopModal.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultAutoPopModalSlice } from '@/redux/modules/autoPopModal/autoPopModal.types';
import { HYDRATE_CLIENT_STATE, HYDRATE_CLIENT_STATE_ACTION } from '../actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';

const autoPopModalSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(autoOpenSignUpModal.fulfilled, (state, { payload: dateLastShown }) => {
            state.dateLastShown = dateLastShown;
        });
        builder.addCase(submitLogin.fulfilled, (state) => {
            state.hasLoggedInBefore = true;
        });
        builder.addCase(submitLoginWithToken.fulfilled, (state) => {
            state.hasLoggedInBefore = true;
        });
        builder.addCase<typeof HYDRATE_CLIENT_STATE, HYDRATE_CLIENT_STATE_ACTION>(
            HYDRATE_CLIENT_STATE,
            (state, { payload }) => {
                state.dateLastShown = payload.dateLastShown;
                state.disableModalForTesting = payload.disableModalForTesting;
                state.hasLoggedInBefore = payload.hasLoggedInBefore;
            }
        );
    },
    initialState: defaultAutoPopModalSlice,
    name: 'autoPopModal',
    reducers: {},
});

export const { reducer: autoPopModalReducer } = autoPopModalSlice;
