import {
    activateTwoStepAuthentication,
    deactivateTwoStepAuthentication,
    fetchTwoStepAuthenticationActivationStatus,
} from './twoStepAuthentication.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultTwoStepAuthenticationSlice } from './twoStepAuthentication.types';

const twoStepAuthenticationSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchTwoStepAuthenticationActivationStatus.pending, (slice) => {
            slice.activated = undefined;
            slice.error = undefined;
            slice.loaded = false;
        });
        builder.addCase(fetchTwoStepAuthenticationActivationStatus.fulfilled, (slice, { payload }) => {
            slice.activated = payload;
            slice.loaded = true;
        });
        builder.addCase(fetchTwoStepAuthenticationActivationStatus.rejected, (slice, { error }) => {
            slice.activated = undefined;
            slice.error = error.message;
            slice.loaded = true;
        });

        builder.addCase(activateTwoStepAuthentication.pending, (slice) => {
            slice.activated = undefined;
            slice.error = undefined;
            slice.loaded = false;
        });
        builder.addCase(activateTwoStepAuthentication.fulfilled, (slice, { payload }) => {
            slice.activated = payload;
            slice.loaded = true;
        });

        builder.addCase(deactivateTwoStepAuthentication.pending, (slice) => {
            slice.activated = undefined;
            slice.error = undefined;
            slice.loaded = false;
        });
        builder.addCase(deactivateTwoStepAuthentication.fulfilled, (slice, { payload }) => {
            slice.activated = payload;
            slice.loaded = true;
        });
    },
    initialState: defaultTwoStepAuthenticationSlice,
    name: 'TwoStepAuthentication',
    reducers: {},
});

export const { reducer: twoStepAuthenticationReducer } = twoStepAuthenticationSlice;
