import { AttributionData, getRegisterAccountAnalytics } from '@/redux/modules/analytics';
import { TypedActionWithPayload } from '@/types/redux';

export type CreateAccountSlice = {
    error: Error;
    submitted: boolean;
    success: boolean;
};

export const defaultCreateAccountSlice: CreateAccountSlice = {
    error: null,
    submitted: false,
    success: false,
};

export type CreateAccountPayload = {
    agree: boolean;
    attributionData: AttributionData;
    deviceId: string | undefined;
    emailAddress: string;
    onExistingAccount?: () => void;
    onSuccessCallback?: () => void;
    pageName: string;
    sessionId: string | undefined;
    shouldOpenModal: boolean;
    url: string;
    visitorCode: string;
};

/**
 * @deprecated If using toolkit, use submitCreateAccount.fulfilled instead
 */
export const CREATE_ACCOUNT_SUCCESS = 'la/ui/createAccount/SUCCESS';
/**
 * @deprecated If using toolkit, use submitCreateAccount.fulfilled instead
 */
export type CreateAccountSuccessAction = TypedActionWithPayload<
    typeof CREATE_ACCOUNT_SUCCESS,
    string,
    {
        actionTime: number;
        analytics: ReturnType<typeof getRegisterAccountAnalytics>;
    }
>;
