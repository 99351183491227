import { FetchLiveAuctionStatusResponseData } from '@/redux/api/liveCatalogStatus';
import type { AuctionStatus } from '@/types/LiveAuctionState';
import type { TypedActionWithPayload } from '@/types/redux';

export const LOAD_LIVE_CATALOG_STATUS_FAIL = 'la/domain/liveCatalogStatus/LOAD_FAIL';
export const LOAD_LIVE_CATALOG_STATUS_REQUEST = 'la/domain/liveCatalogStatus/LOAD_REQUEST';

/** Live auction status is either a status of 'notLoaded' with nothing else or the entire auction status */
export type LiveAuctionStatusNotLoaded = {
    status: 'notLoaded';
};

export type LiveAuctionStatusLoaded = FetchLiveAuctionStatusResponseData & {
    status: Exclude<AuctionStatus, 'notLoaded'>;
};

export const LOAD_LIVE_CATALOG_STATUS_SUCCESS = 'la/domain/liveCatalogStatus/LOAD_SUCCESS';
export type LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    LiveAuctionStatusNotLoaded | LiveAuctionStatusLoaded,
    {
        actionTime: number;
        bidderId: number;
        catalogId: number;
    }
>;
