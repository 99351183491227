import { TypedActionWithPayload } from '@/types/redux';

export const LOAD_ABOUT_DATA_FAIL = 'la/domain/about/LOAD_FAIL';
export type LOAD_ABOUT_DATA_FAIL_ACTION = TypedActionWithPayload<typeof LOAD_ABOUT_DATA_FAIL, string>;

export const LOAD_ABOUT_DATA_REQUEST = 'la/domain/about/LOAD_REQUEST';
export type LOAD_ABOUT_DATA_REQUEST_ACTION = TypedActionWithPayload<typeof LOAD_ABOUT_DATA_REQUEST>;

export const LOAD_ABOUT_DATA_SUCCESS = 'la/domain/about/LOAD_SUCCESS';
export type LOAD_ABOUT_DATA_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_ABOUT_DATA_SUCCESS,
    any,
    {
        actionTime: number;
    }
>;
