import { AuctionCalendarPayload, TodaysCatalogCount } from '@/redux/modules/auctionCalendar/auctionCalendar.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchCatalogAdIfNeeded } from '@/redux/modules/catalog/ad/catalogAd.actions';
import { fetchCatalogsIfNeeded } from '@/redux/modules/catalog/catalogs/catalog.actions';
import { getAuctionCalendarFacets, getAuctionCalendarIds, getCatalogsTodayCount } from './auctionCalendar.selectors';
import { getCalendarResultsApi, getCatalogsTodayCountApi, SearchAuctionFacet } from './auctionCalendar.api';
import { getDeployment } from '@/redux/modules/config';
import { getUserExcludedHouses } from '@/redux/modules/search/exclusions/searchExclusions.selectors';

export const fetchAuctionCalendarResults = createAsyncThunk<AuctionCalendarPayload, any>(
    'la/ui/auctionCalendar/fetchAuctionCalendarResults',
    async (queryParams, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const searchExclusions = getUserExcludedHouses(state);

        const auctionCalendarQuery = {
            client: 'web',
            client_version: '5.0.0',
            excludedHouses: JSON.stringify(searchExclusions),
            max_facet_values: 3,
            offset: new Date().getTimezoneOffset(),
            pagesize: queryParams.pageSize || queryParams.pagesize,
            ...queryParams,
        };

        const response = await getCalendarResultsApi({
            deployment,
            queryParams: auctionCalendarQuery,
        });
        return response.payload;
    }
);

export const fetchCatalogsTodayCount = createAsyncThunk<TodaysCatalogCount, void>(
    'la/ui/auctionCalendar/fetchCatalogsTodayCount',
    async (_, { getState }) => {
        const deployment = getDeployment(getState());
        const response = await getCatalogsTodayCountApi({ deployment });
        return response.data[0];
    }
);

export const fetchCatalogsTodayCountIfNeeded = createAsyncThunk<void, void>(
    'la/ui/auctionCalendar/fetchCatalogsTodayCountIfNeeded',
    async (_, { dispatch, getState }) => {
        const todayCount = getCatalogsTodayCount(getState());
        if (!Boolean(todayCount)) {
            await dispatch(fetchCatalogsTodayCount());
        }
    }
);

export const updateFacetsSelected = createAsyncThunk<{ facets: SearchAuctionFacet[] }, void>(
    'la/ui/auctionCalendar/updateFacetsSelected',
    (_, { getState }) => ({
        facets: getAuctionCalendarFacets(getState()),
    })
);

export const fetchDataForAuctionCalendarPage = createAsyncThunk<void, any>(
    'la/ui/auctionCalendar/fetchDataForAuctionCalendarPage',
    async (queryParams, { dispatch, getState }) => {
        await Promise.all([
            dispatch(fetchCatalogsTodayCountIfNeeded()),
            dispatch(fetchCatalogAdIfNeeded({ pageName: 'calendar' })),
        ]);
        // in order to re-arrange the facets set in state we need to adjust them after the search call
        await dispatch(fetchAuctionCalendarResults(queryParams));
        await dispatch(updateFacetsSelected());

        const catalogIds = getAuctionCalendarIds(getState());
        await dispatch(fetchCatalogsIfNeeded({ catalogIds }));
    }
);
