import { SavedSearch } from '@/redux/api/saveSearch';
import { TypedActionWithPayload } from '@/types/redux';

export const DELETE_SAVED_SEARCH_FAIL = 'DELETE_SAVED_SEARCH_FAIL';
export const DELETE_SAVED_SEARCH_REQUEST = 'DELETE_SAVED_SEARCH_REQUEST';
export const DELETE_SAVED_SEARCH_SUCCESS = 'DELETE_SAVED_SEARCH_SUCCESS';

export const LOAD_SAVED_SEARCHES_FAIL = 'LOAD_SAVED_SEARCHES_FAIL';
export const LOAD_SAVED_SEARCHES_REQUEST = 'LOAD_SAVED_SEARCHES_REQUEST';
export const LOAD_SAVED_SEARCHES_SUCCESS = 'LOAD_SAVED_SEARCHES_SUCCESS';
export type LoadSavedSearchesSuccessAction = TypedActionWithPayload<
    typeof LOAD_SAVED_SEARCHES_SUCCESS,
    SavedSearch[],
    {
        actionTime: number;
        page: number;
        pageSize: number;
        totalRecords: number;
    }
>;

export const SUBSCRIBE_SAVED_SEARCH_FAIL = 'SUBSCRIBE_SAVED_SEARCH_FAIL';
export const SUBSCRIBE_SAVED_SEARCH_REQUEST = 'SUBSCRIBE_SAVED_SEARCH_REQUEST';
export const SUBSCRIBE_SAVED_SEARCH_SUCCESS = 'SUBSCRIBE_SAVED_SEARCH_SUCCESS';

export const UNSUBSCRIBE_SAVED_SEARCH_FAIL = 'UNSUBSCRIBE_SAVED_SEARCH_FAIL';
export const UNSUBSCRIBE_SAVED_SEARCH_REQUEST = 'UNSUBSCRIBE_SAVED_SEARCH_REQUEST';
export const UNSUBSCRIBE_SAVED_SEARCH_SUCCESS = 'UNSUBSCRIBE_SAVED_SEARCH_SUCCESS';

export const SUBSCRIBE_ALL_SAVED_SEARCH_FAIL = 'SUBSCRIBE_ALL_SAVED_SEARCH_FAIL';
export const SUBSCRIBE_ALL_SAVED_SEARCH_REQUEST = 'SUBSCRIBE_ALL_SAVED_SEARCH_REQUEST';
export const SUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS = 'SUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS';

export const UNSUBSCRIBE_ALL_SAVED_SEARCH_FAIL = 'UNSUBSCRIBE_ALL_SAVED_SEARCH_FAIL';
export const UNSUBSCRIBE_ALL_SAVED_SEARCH_REQUEST = 'UNSUBSCRIBE_ALL_SAVED_SEARCH_REQUEST';
export const UNSUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS = 'UNSUBSCRIBE_ALL_SAVED_SEARCH_SUCCESS';

export const LOAD_SAVED_SEARCH_ITEMS_FAIL = 'LOAD_SAVED_SEARCH_ITEMS_FAIL';
export const LOAD_SAVED_SEARCH_ITEMS_REQUEST = 'LOAD_SAVED_SEARCH_ITEMS_REQUEST';
export const LOAD_SAVED_SEARCH_ITEMS_SUCCESS = 'LOAD_SAVED_SEARCH_ITEMS_SUCCESS';

export const SET_SIGNUP_SAVE_SEARCH_PARAMS = 'SET_SIGNUP_SAVE_SEARCH_PARAMS';

export const SAVED_SEARCH_FILTER_CHANGE = 'SAVED_SEARCH_FILTER_CHANGE';

export const SAVED_SEARCH_ITEMS_FILTER_CHANGE = 'SAVED_SEARCH_ITEMS_FILTER_CHANGE';

export const SAVE_SEARCH_FAIL = 'la/ui/saveSearch/FAIL';
export const SAVE_SEARCH_REQUEST = 'la/ui/saveSearch/REQUEST';
export const SAVE_SEARCH_SUCCESS = 'la/ui/saveSearch/SUCCESS';

export const SAVE_RECENT_SEARCH_FAIL = 'la/ui/saveRecentSearch/FAIL';
export const SAVE_RECENT_SEARCH_REQUEST = 'la/ui/saveRecentSearch/REQUEST';
export const SAVE_RECENT_SEARCH_SUCCESS = 'la/ui/saveRecentSearch/SUCCESS';

export const DELETE_SAVE_SEARCH_FAIL = 'la/ui/deleteSaveSearch/FAIL';
export const DELETE_SAVE_SEARCH_REQUEST = 'la/ui/deleteSaveSearch/REQUEST';
export const DELETE_SAVE_SEARCH_SUCCESS = 'la/ui/deleteSaveSearch/SUCCESS';
