import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { ModalContext } from '../../modal';
import { OPEN_COMPLETE_ACCOUNT_SIGNUP_MODAL } from '../../actions';

type OpenFromCheckerArgs = {
    modalContext?: ModalContext;
    trigger: string;
};

export const openCompleteAccountSignupModalFromChecker = createAsyncThunk<string, OpenFromCheckerArgs>(
    'la/ui/shouldLogin/openCompleteAccountSignupModalFromChecker',
    ({ modalContext, trigger }, { dispatch }) => {
        dispatch({
            // only adds modalContext if present
            payload: { ...(modalContext && { modalContext }) },
            trigger,
            type: OPEN_COMPLETE_ACCOUNT_SIGNUP_MODAL,
        });
        return trigger;
    }
);

/**
 * stopTracker closes the modals removes the trigger.
 * this is handled in the reducer, so this thunk is a noop (but doesn't accept the variable noop 🤷)
 */
export const stopTracker = createAsyncThunk('la/ui/shouldLogin/stopTracker', () => {});

export const setTrigger = createAsyncThunk<string, string>('la/ui/shouldLogin/setTrigger', (guid) => guid);
