import { AppDispatch, AppGetState } from '@/redux/store';
import { fetchAboutData } from '@/redux/api/page';
import { getDeployment } from './config';
import {
    LOAD_ABOUT_DATA_FAIL,
    LOAD_ABOUT_DATA_FAIL_ACTION,
    LOAD_ABOUT_DATA_REQUEST,
    LOAD_ABOUT_DATA_REQUEST_ACTION,
    LOAD_ABOUT_DATA_SUCCESS,
    LOAD_ABOUT_DATA_SUCCESS_ACTION,
} from './actions';

/* ACTION CREATORS */
export const loadAboutData = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);

        dispatch({
            type: LOAD_ABOUT_DATA_REQUEST,
        } as LOAD_ABOUT_DATA_REQUEST_ACTION);

        const response = await fetchAboutData({ deployment });
        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response.data,
            type: LOAD_ABOUT_DATA_SUCCESS,
        } as LOAD_ABOUT_DATA_SUCCESS_ACTION);
    } catch (error) {
        return dispatch({
            error: true,
            payload: error,
            type: LOAD_ABOUT_DATA_FAIL,
        } as LOAD_ABOUT_DATA_FAIL_ACTION);
    }
};
