import { EventTypes } from 'redux-segment';
import { TrackProductClickedProperties } from '../analytics';
import { TrackTwoFactorAuthentication, TrackTwoFactorAuthenticationTryAnotherWay } from '@/types/analytics';
import { TypedActionWithPayload } from '@/types/redux';

export const IDENTIFY_ANALYTICS = 'IDENTIFY_ANALYTICS';
export type IDENTIFY_ANALYTICS_ACTION = TypedActionWithPayload<
    typeof IDENTIFY_ANALYTICS,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_ATTRIBUTION_DATA = 'TRACK_ATTRIBUTION_DATA';
export type TRACK_ATTRIBUTION_DATA_ACTION = TypedActionWithPayload<
    typeof TRACK_ATTRIBUTION_DATA,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_USER_AUTHENTICATED_DATA = 'TRACK_USER_AUTHENTICATED_DATA';
export type TRACK_USER_AUTHENTICATED_DATA_ACTION = TypedActionWithPayload<
    typeof TRACK_USER_AUTHENTICATED_DATA,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_PLACED_SEARCH = 'TRACK_PLACED_SEARCH';
export type TrackPlacedSearchAction = TypedActionWithPayload<
    typeof TRACK_PLACED_SEARCH,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_ONBOARDING_WALKTHROUGH = 'TRACK_ONBOARDING_WALKTHROUGH';
export type TrackOnboardingWalkthroughAction = TypedActionWithPayload<
    typeof TRACK_ONBOARDING_WALKTHROUGH,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_TWO_FACTOR_AUTHENTICATION = 'TRACK_TWO_FACTOR_AUTHENTICATION';
export type TrackTwoFactorAuthenticationAction = TypedActionWithPayload<
    typeof TRACK_TWO_FACTOR_AUTHENTICATION,
    {},
    {
        analytics: {
            eventPayload: {
                event: TrackTwoFactorAuthentication;
                properties: {
                    category: string;
                    label: TrackTwoFactorAuthentication;
                };
            };
            eventType: EventTypes;
        };
    }
>;

export const TRACK_TWO_FACTOR_AUTHENTICATION_TRY_ANOTHER_WAY = 'TRACK_TWO_STEP_VERIFICATION_TRY_ANOTHER_WAY';
export type TrackTwoFactorAuthenticationTryAnotherWayAction = TypedActionWithPayload<
    typeof TRACK_TWO_FACTOR_AUTHENTICATION_TRY_ANOTHER_WAY,
    undefined,
    {
        analytics: {
            eventPayload: {
                event: TrackTwoFactorAuthenticationTryAnotherWay;
                properties: {
                    category: string;
                    label: TrackTwoFactorAuthenticationTryAnotherWay;
                };
            };
            eventType: EventTypes;
        };
    }
>;

export const TRACK_CLICK = 'TRACK_CLICK';
export type TRACK_CLICK_ACTION = TypedActionWithPayload<
    typeof TRACK_CLICK,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_VIEW = 'TRACK_VIEW';
export type TRACK_VIEW_ACTION = TypedActionWithPayload<
    typeof TRACK_VIEW,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_CLICK_FOR_ALGOLIA = 'TRACK_CLICK_FOR_ALGOLIA';
export type TRACK_CLICK_FOR_ALGOLIA_ACTION = TypedActionWithPayload<
    typeof TRACK_CLICK_FOR_ALGOLIA,
    {
        itemId: number;
        position: number;
    }
>;

export const TRACK_CONVERSION_FOR_ALGOLIA = 'TRACK_CONVERSION_FOR_ALGOLIA';
export type TRACK_CONVERSION_FOR_ALGOLIA_ACTION = TypedActionWithPayload<
    typeof TRACK_CONVERSION_FOR_ALGOLIA,
    {
        itemId: number;
    }
>;

export const TRACK_PAGEVIEW = 'TRACK_PAGEVIEW';
export type TRACK_PAGEVIEW_ACTION = TypedActionWithPayload<
    typeof TRACK_PAGEVIEW,
    {},
    {
        actionTime: number;
        analytics: {
            eventPayload: {
                // to support Kameleoon integration, matches pageType from analytics:trackPageView call
                name?: string;
                properties: any;
            };
            eventType: any;
        };
    }
>;

export const TRACK_SOCIAL_NETWORK_SHARE = 'TRACK_SOCIAL_NETWORK_SHARE';
export type TRACK_SOCIAL_NETWORK_SHARE_ACTION = TypedActionWithPayload<
    typeof TRACK_SOCIAL_NETWORK_SHARE,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_ABANDONED_MODAL = 'TRACK_ABANDONED_MODAL';
export type TRACK_ABANDONED_MODAL_ACTION = TypedActionWithPayload<
    typeof TRACK_ABANDONED_MODAL,
    {},
    {
        analytics: any;
    }
>;

export const TRACK_ABSENTEE_BID_FLOW_STEP = 'TRACK_ABSENTEE_BID_FLOW_STEP';
export type TRACK_ABSENTEE_BID_FLOW_STEP_ACTION = TypedActionWithPayload<
    typeof TRACK_ABSENTEE_BID_FLOW_STEP,
    {},
    { analytics: any }
>;

export const TRACK_SEND_SELLER_MESSAGE = 'TRACK_SEND_SELLER_MESSAGE';
export type TrackSendSellerMessageAction = TypedActionWithPayload<
    typeof TRACK_SEND_SELLER_MESSAGE,
    {},
    {
        analytics: {
            eventPayload: {
                event: 'Messaged Seller';
                properties: {
                    catalogId: number;
                    houseId: number;
                    itemId: number;
                };
            };
            eventType: EventTypes;
        };
    }
>;

export const TrackSendSupportMessage = 'TRACK_SEND_SUPPORT_MESSAGE';
export type TrackSendSupportMessageAction = TypedActionWithPayload<
    typeof TrackSendSupportMessage,
    {},
    {
        analytics: {
            eventPayload: {
                event: 'Messaged Support';
                properties: {
                    catalogId: number;
                    itemId: number;
                };
            };
            eventType: EventTypes;
        };
    }
>;

export const TrackProductClicked = 'TRACK_PRODUCT_CLICKED';
export type TrackProductClickedAction = TypedActionWithPayload<
    typeof TrackProductClicked,
    {},
    {
        analytics: {
            eventPayload: {
                event: 'Product Clicked';
                properties: TrackProductClickedProperties;
            };
            eventType: EventTypes;
        };
    }
>;

export const TrackGetHelpViewed = 'TRACK_GET_HELP_VIEWED';
export type TrackGetHelpViewedAction = TypedActionWithPayload<
    typeof TrackGetHelpViewed,
    {},
    {
        analytics: {
            eventPayload: {
                event: 'Get Help Viewed';
            };
            eventType: EventTypes;
        };
    }
>;

export const TrackWonItemsPageCheckoutClicked = 'TRACK_WON_ITEMS_PAGE_CHECKOUT_CLICKED';
export type TrackWonItemsPageCheckoutClickedAction = TypedActionWithPayload<
    typeof TrackWonItemsPageCheckoutClicked,
    {},
    {
        analytics: {
            eventPayload: {
                event: 'Won Items Page Checkout Clicked';
            };
            eventType: EventTypes;
        };
    }
>;
