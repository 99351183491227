import { AuctionNearMeHit } from '@/pages/AuctionsNearMe/types/AuctionsNearMe.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultAuctionsNearMeSlice } from '@/redux/modules/auctionsNearMe/auctionsNearMe.types';

const auctionsNearMeSlice = createSlice({
    initialState: defaultAuctionsNearMeSlice,
    name: 'auctionsNearMe',
    reducers: {
        clearAuctionsNearMeHits: (slice) => {
            slice.hits = [];
        },
        populateAuctionsNearMeHits: (slice, { payload }: PayloadAction<AuctionNearMeHit[]>) => {
            slice.hits = payload;
        },
    },
});

export const { reducer: auctionsNearMeReducer } = auctionsNearMeSlice;

export const { clearAuctionsNearMeHits, populateAuctionsNearMeHits } = auctionsNearMeSlice.actions;
