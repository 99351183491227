import { CategorySearchResultPayload } from '@/redux/modules/search/search.types';
import { PayloadlessAction, TypedActionWithPayloadMetaActionTime } from '@/types/redux';

export const LOAD_CATEGORY_RESULTS_FAIL = 'LOAD_CATEGORY_RESULTS_FAIL';
export type LOAD_CATEGORY_RESULTS_FAIL_ACTION = PayloadlessAction<typeof LOAD_CATEGORY_RESULTS_FAIL>;

export const LOAD_CATEGORY_RESULTS_REQUEST = 'LOAD_CATEGORY_RESULTS_REQUEST';
export type LOAD_CATEGORY_RESULTS_REQUEST_ACTION = PayloadlessAction<typeof LOAD_CATEGORY_RESULTS_REQUEST>;

export const LOAD_CATEGORY_RESULTS_SUCCESS = 'LOAD_CATEGORY_RESULTS_SUCCESS';
export type LOAD_CATEGORY_RESULTS_SUCCESS_ACTION = TypedActionWithPayloadMetaActionTime<
    typeof LOAD_CATEGORY_RESULTS_SUCCESS,
    CategorySearchResultPayload
>;
