import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { deleteAccount } from '@/redux/modules/account/deleteAccount/deleteAccount.api';
import { getDeployment } from '@/redux/modules/config';
import { logOutBidder } from '@/redux/modules/account/logout/logout.actions';
import { SubmitDeleteAccountParams } from './deleteAccount.types';
import noop from '@/utils/noop';

export const submitDeleteAccount = createAsyncThunk<void, SubmitDeleteAccountParams, AsyncThunkConfigWithRejectValue>(
    'la/ui/deleteAccount/submitDeleteAccount',
    async ({ bidderId, token }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);

            const response = await deleteAccount({
                bidderId,
                deployment,
                token,
            });

            // Only logout if the delete was successful
            if (!response.error) {
                dispatch(logOutBidder());
            }
        } catch (error) {
            const publishedError = typeof error === 'string' ? new Error(error) : (error as Error);
            return rejectWithValue(publishedError);
        }
    }
);

export const resetAccountDeleteSlice = createAsyncThunk('la/ui/deleteAccount/resetAccountDeleteSlice', noop);
