import { BidderAddress } from '@/types/BidderAddresses';
import { postAddBidderAddressV2 } from '@/redux/modules/account/updateAccount/updateAccount.api';
import { TypedActionWithPayload } from '@/types/redux';

export type UpdateAccountSlice = {
    addressValidationError: string;
    error: boolean;
    errorMessage: string | null | undefined;
    original: BidderAddress;
    submitted: boolean;
    success: boolean;
    suggestion: BidderAddress | null | undefined;
    toastMessage: string;
};

export const defaultUpdateAccountSlice: UpdateAccountSlice = {
    addressValidationError: '',
    error: false,
    errorMessage: null,
    original: undefined,
    submitted: false,
    success: false,
    suggestion: null,
    toastMessage: '',
};

export const UPDATE_ACCOUNT_FAIL = 'la/ui/updateAccount/FAIL';
export const UPDATE_ACCOUNT_REQUEST = 'la/ui/updateAccount/REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'la/ui/updateAccount/SUCCESS';
export const UPDATE_ACCOUNT_SUGGESTION = 'la/ui/updateAccount/SUGGESTION';
export type UpdateAccountSuggestionAction = TypedActionWithPayload<
    typeof UPDATE_ACCOUNT_SUGGESTION,
    Awaited<ReturnType<typeof postAddBidderAddressV2>> & {
        payload: {
            originalAddress: BidderAddress;
        };
    },
    { actionTime: number }
>;
export const UPDATE_ACCOUNT_TOAST_MESSAGE = 'la/ui/updateAccount/TOAST_MESSAGE';
export const SET_ADDRESS_VALIDATION_ERROR = 'la/ui/updateAccount/ADDRESS_VALIDATION_ERROR';
