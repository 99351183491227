import { createSlice } from '@reduxjs/toolkit';
import { defaultAuctionCalendarSlice } from '@/redux/modules/auctionCalendar/auctionCalendar.types';
import { fetchAuctionCalendarResults, fetchCatalogsTodayCount, updateFacetsSelected } from './auctionCalendar.actions';

const auctionCalendarSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchAuctionCalendarResults.pending, (slice) => {
            slice.submitted = true;
            slice.success = false;
            slice.error = false;
        });
        builder.addCase(fetchAuctionCalendarResults.fulfilled, (slice, { payload }) => {
            slice.catalogIds = payload.results.map((calendar) => calendar.catid);
            slice.facets = payload.facets;
            slice.totalFound = payload.count;
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(fetchAuctionCalendarResults.rejected, (slice) => {
            slice.submitted = false;
            slice.error = true;
        });

        builder.addCase(fetchCatalogsTodayCount.pending, (slice) => {
            slice.submitted = true;
            slice.success = false;
            slice.error = false;
        });
        builder.addCase(fetchCatalogsTodayCount.fulfilled, (slice, { payload }) => {
            slice.catalogsTodayCount = payload.count;
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(fetchCatalogsTodayCount.rejected, (slice) => {
            slice.submitted = false;
            slice.error = true;
        });

        builder.addCase(updateFacetsSelected.fulfilled, (slice, { payload }) => {
            slice.facets = payload.facets;
        });
    },
    initialState: defaultAuctionCalendarSlice,
    name: 'auctionCalendar',
    reducers: {},
});

export const { reducer: auctionCalendarReducer } = auctionCalendarSlice;
