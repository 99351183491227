import { createSlice } from '@reduxjs/toolkit';
import { defaultShouldLoginSlice, ShouldLoginSlice } from '@/redux/modules/account/shouldLogin/shouldLogin.types';
import { DISMISS_MODAL } from '../../actions';
import {
    openCompleteAccountSignupModalFromChecker,
    setTrigger,
    stopTracker,
} from '@/redux/modules/account/shouldLogin/shouldLogin.actions';

type SliceModalKey = keyof Omit<ShouldLoginSlice, 'trigger'>;

const openCompleteAccountModal = (slice: ShouldLoginSlice, modal: SliceModalKey, trigger: string): ShouldLoginSlice => {
    return {
        ...slice,
        completeAccountModalOpen: true,
        startTracking: true,
        [`${modal}`]: true,
        trigger,
    };
};

const closeAllModals = (slice: ShouldLoginSlice, clearTracking: boolean, clearTrigger: boolean): ShouldLoginSlice => ({
    ...defaultShouldLoginSlice,
    startTracking: clearTracking ? defaultShouldLoginSlice.startTracking : slice.startTracking,
    trigger: clearTrigger ? defaultShouldLoginSlice.trigger : slice.trigger,
});

const shouldLoginSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(openCompleteAccountSignupModalFromChecker.fulfilled, (slice, { payload: trigger }) =>
            openCompleteAccountModal(slice, 'completeAccountModalOpen', trigger)
        );
        builder.addCase(stopTracker.fulfilled, (slice) => closeAllModals(slice, true, true));
        builder.addCase(DISMISS_MODAL, (slice) => closeAllModals(slice, false, false));

        builder.addCase(setTrigger.fulfilled, (state, { payload: trigger }) => {
            state.trigger = trigger;
        });
    },
    initialState: defaultShouldLoginSlice,
    name: 'shouldLogin',
    reducers: {},
});

export const { reducer: shouldLoginReducer } = shouldLoginSlice;
