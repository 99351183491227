import { ApiHosts, handleResponseOld, makePost } from '@/redux/api/helpers';
import { PostPlaceAbsenteeBidParams, PostPlaceAbsenteeBidResponse } from './absenteeBid.types';

/**
 * makes POST to mainhost API absentee bidder service
 * @function postPlaceAbsenteeBid
 * @category Mainhost API
 * @param {PostPlaceAbsenteeBidParams}
 * @see https://mainhost-DEPLOYMENT.liveauctioneers.com/api/abs_bid
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/libs/MOBILE_API.php#L555
 */
export const postPlaceAbsenteeBid = ({ authToken, deployment, details }: PostPlaceAbsenteeBidParams) => {
    return new Promise<PostPlaceAbsenteeBidResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'api/abs_bid',
            authToken,
            deployment,
            path: ApiHosts.Mainhost,
        });
        request.type('form');
        request.send({
            accept_terms: true,
            bid: details.bidAmount,
            cat_id: details.catalogId,
            client: details.client,
            device_type: details.deviceType,
            lot_id: details.itemId,
            minimum_bid_limit: details.minimumBidLimit,
        });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: (body) => {
                    const payload: PostPlaceAbsenteeBidResponse = {
                        currentBid: Number(body.current_bid),
                        isCurrentHighBid: body.is_current_high_bid,
                        isReserveMet: body.is_reserve_met,
                        leadingBid: Number(body.leading_bid),
                        message: body.message,
                        success: body.success,
                    };

                    return payload;
                },
            })
        );
    });
};
