import { BidderAddress } from '@/types/BidderAddresses';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    FetchBidderDataIfNeededParams,
    FetchBidderDataParams,
    FetchBidderDataResponsePayload,
    LOAD_BIDDER_DATA_FAIL,
    LOAD_BIDDER_DATA_SUCCESS,
    LoadBidderDataFailAction,
    LoadBidderDataSuccessAction,
    UserRegistrationStatus,
} from '@/redux/modules/account/user/user.types';
import {
    getAuthToken,
    getUserRegistrationStatus,
    shouldFetchUserData,
} from '@/redux/modules/account/user/user.selectors';
import { getBidderData } from './user.api';
import { getDeployment } from '@/redux/modules/config';
import { loadBidderData } from '@/redux/modules/bidder/data/bidderData.actions';
import { logOutBidder } from '@/redux/modules/account/logout/logout.actions';

export const fetchBidderData = createAsyncThunk<FetchBidderDataResponsePayload, FetchBidderDataParams>(
    'la/ui/user/fetchBidderData',
    async ({ authToken, deployment }, { dispatch, rejectWithValue }) => {
        try {
            const response = await getBidderData({ authToken, deployment });
            await dispatch(
                loadBidderData({
                    resellerType: response.data.resellerType,
                })
            );

            const successAction: LoadBidderDataSuccessAction = {
                error: false,
                meta: {
                    actionTime: Date.now(),
                },
                payload: response.data,
                type: LOAD_BIDDER_DATA_SUCCESS,
            };
            dispatch(successAction);

            return response.data;
        } catch (error) {
            const errorAction: LoadBidderDataFailAction = {
                error: true,
                meta: { token: authToken },
                payload: error,
                type: LOAD_BIDDER_DATA_FAIL,
            };
            dispatch(errorAction);
            dispatch(logOutBidder());
            return rejectWithValue(error);
        }
    }
);

export const fetchBidderDataIfNeeded = createAsyncThunk<void, FetchBidderDataIfNeededParams>(
    'la/ui/user/fetchBidderDataIfNeeded',
    async ({ authToken, force }, { dispatch, getState }) => {
        const state = getState();
        const token = authToken || getAuthToken(state);
        const bidderType = getUserRegistrationStatus(state, token);
        const deployment = getDeployment(state);

        if (
            Boolean(token) &&
            bidderType !== UserRegistrationStatus.Unregistered &&
            (force || shouldFetchUserData(state, token))
        ) {
            await dispatch(fetchBidderData({ authToken: token, deployment }));
        }
    }
);

export const storeSavedAddress = createAsyncThunk<BidderAddress, BidderAddress>(
    'la/ui/user/storeSavedAddress',
    (address) => address
);
