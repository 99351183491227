import { activateTwoStepAuthentication } from '@/redux/modules/account/twoStepAuthentication/twoStepAuthentication.actions';
import {
    CodeDeliveryMethod,
    GetUserByTokenResponsePayload,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LoginFailAction,
    LoginResponsePayload,
    LoginSuccessAction,
    PostLoginResponse,
    SubmitLoginInfo,
    SubmitTokenLoginInfo,
} from './login.types';
import { completeLoginModal } from '@/redux/modules/modal';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { Deployment } from '@liveauctioneers/hammer-ui-payments/types';
import { fetchBidderDataIfNeeded } from '../user/user.actions';
import { getBidderEmail, getSessionId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getLoginAnalytics, trackTwoFactorAuthentication } from '../../analytics';
import {
    getUserByToken,
    postFetchObfuscatedPhoneNumber,
    postLogin,
    postLoginWithTwoStepAuthentication,
    postSendTwoStepAuthentication,
    postSubmitEmailVerificationCode,
} from '@/redux/modules/account/login/login.api';
import { loadBidderData } from '@/redux/modules/bidder/data/bidderData.actions';
import { loadPageData } from '@/redux/modules/appState';
import { setAuthCookie } from '@/redux/modules/cookies';
import { TrackTwoFactorAuthentication } from '@/types/analytics';
import getExpirationFromToken from '@/utils/getExpirationFromToken';

export const submitLogin = createAsyncThunk<LoginResponsePayload, SubmitLoginInfo>(
    'la/ui/login/submitLogin',
    async (
        {
            emailAddress,
            featureFlags,
            location,
            newAccount,
            onLogin,
            password,
            shouldCloseModal = true,
            twoStepAuthenticationCode,
        },
        { dispatch, getState }
    ) => {
        dispatch({ type: LOGIN_REQUEST });
        try {
            const state = getState();
            const deployment = getDeployment(state);
            const sessionId = getSessionId();
            let response: PostLoginResponse;
            if (Boolean(twoStepAuthenticationCode)) {
                // if two-step authentication code is incorrect, an error is thrown.
                // submitLogin.rejected in login.reducer will handle it from there.
                response = await postLoginWithTwoStepAuthentication({
                    deployment,
                    password,
                    sessionId,
                    twoStepAuthenticationCode,
                    username: emailAddress,
                });
                await dispatch(trackTwoFactorAuthentication(TrackTwoFactorAuthentication.OTPCodeAccepted));
            } else {
                // if two-step authentication is needed, an error is thrown.
                // submitLogin.rejected in login.reducer will handle it from there.
                response = await postLogin({
                    deployment,
                    password,
                    sessionId,
                    username: emailAddress,
                });
            }

            const isProd = deployment === Deployment.Prod;
            // TODO: In order to release this code until SMS code verification is ready, we're going to avoid turning 2FA for new users
            //  Once SMS codes are being sent, we can (probably) remove the && false
            // if the user is a new user on prod, we must activate MFA
            if (newAccount && Boolean(response?.data?.bidder?.bidderId) && isProd && false) {
                dispatch(
                    activateTwoStepAuthentication({
                        bidderId: response.data.bidder.bidderId,
                        populatedNewAccountAuthToken: response.data.token,
                    })
                );
            }

            if (shouldCloseModal) {
                dispatch(completeLoginModal());
            }

            await Promise.all([
                dispatch(loadPageData({ featureFlags, location })),
                dispatch(
                    loadBidderData({
                        bidderId: response.data.bidder.bidderId,
                        resellerType: response.data.resellerType,
                        token: response.data.token,
                    })
                ),
            ]);
            onLogin?.();

            const result: LoginResponsePayload = {
                ...response.data,
                bidder: {
                    ...response.data.bidder,
                    ANMS: response.data.annualNMS,
                },
            };
            // @ts-expect-error
            // We're spreading the API response, which still has annualNMS
            // To avoid unnecessary fields on the result, we're deleting it here
            delete result.annualNMS;

            const loginAnalytics = getLoginAnalytics(result.bidder, result.resellerType);

            // remove this once all dependencies are using toolkit and the thunk directly
            const loginSuccessAction: LoginSuccessAction = {
                error: false,
                meta: {
                    ...loginAnalytics,
                    actionTime: Date.now(),
                },
                payload: result,
                type: LOGIN_SUCCESS,
            };
            dispatch(loginSuccessAction);

            const expires = getExpirationFromToken(response.data.token);
            dispatch(setAuthCookie(expires, response.data.token));

            return result;
        } catch (error) {
            const loginFailAction: LoginFailAction = {
                error: true,
                meta: { emailAddress },
                payload: error,
                type: LOGIN_FAIL,
            };

            dispatch(loginFailAction);

            throw error;
        }
    }
);

export const submitLoginWithToken = createAsyncThunk<LoginResponsePayload, SubmitTokenLoginInfo>(
    'la/ui/login/submitLoginWithToken',
    async ({ authToken, featureFlags, location, onLogin, shouldCloseModal = true }, { dispatch, getState }) => {
        dispatch({ type: LOGIN_REQUEST });

        try {
            const state = getState();
            const deployment = getDeployment(state);

            const response: GetUserByTokenResponsePayload = await getUserByToken({
                authToken,
                deployment,
            });

            if (shouldCloseModal) {
                dispatch(completeLoginModal());
            }

            await Promise.all([
                dispatch(loadPageData({ featureFlags, location })),
                dispatch(
                    loadBidderData({
                        bidderId: response.data.bidder.bidderId,
                        resellerType: response.data.resellerType,
                        token: authToken,
                    })
                ),
            ]);

            onLogin?.();

            const result: LoginResponsePayload = {
                ...response.data,
                bidder: {
                    ...response.data.bidder,
                    ANMS: response.data.annualNMS,
                },
                token: authToken,
            };
            // @ts-expect-error
            // We're spreading the API response, which still has annualNMS
            // To avoid unnecessary fields on the result, we're deleting it here
            delete result.annualNMS;

            const loginAnalytics = getLoginAnalytics(result.bidder, result.resellerType);

            // remove this once all dependencies are using toolkit and the thunk directly
            const loginSuccessAction: LoginSuccessAction = {
                error: false,
                meta: {
                    ...loginAnalytics,
                    actionTime: Date.now(),
                },
                payload: result,
                type: LOGIN_SUCCESS,
            };
            dispatch(loginSuccessAction);

            const expires = getExpirationFromToken(authToken);
            dispatch(setAuthCookie(expires, authToken));

            return result;
        } catch (error) {
            const loginFailAction: LoginFailAction = {
                error: true,
                meta: { emailAddress: '' },
                payload: error,
                type: LOGIN_FAIL,
            };

            dispatch(loginFailAction);

            throw error;
        }
    }
);

export const fetchObfuscatedPhoneNumber = createAsyncThunk<string, string>(
    'la/ui/login/fetchObfuscatedPhoneNumber',
    async (emailOrUsername, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const { payload } = await postFetchObfuscatedPhoneNumber({
            deployment,
            emailOrUsername,
        });
        return payload;
    }
);

export const sendTwoStepAuthenticationCodeToPhone = createAsyncThunk<void, string>(
    'la/ui/login/sendTwoStepAuthenticationCodeToPhone',
    async (emailOrUsername, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        await postSendTwoStepAuthentication({
            deliveryType: CodeDeliveryMethod.Sms,
            deployment,
            emailOrUsername,
        });
    }
);

export const sendTwoStepAuthenticationCodeToEmail = createAsyncThunk<void, string>(
    'la/ui/login/sendTwoStepAuthenticationCodeToEmail',
    async (emailOrUsername, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        await postSendTwoStepAuthentication({
            deliveryType: CodeDeliveryMethod.Email,
            deployment,
            emailOrUsername,
        });
    }
);

export const submitEmailVerificationCode = createAsyncThunk<void, string>(
    'la/ui/login/submitEmailVerificationCode',
    async (otp, { dispatch, getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const bidderEmail = getBidderEmail(state);
        await postSubmitEmailVerificationCode({
            deployment,
            otp,
            username: bidderEmail,
        });
        await dispatch(fetchBidderDataIfNeeded({ force: true }));
    }
);
