import { BidderAddress } from '@/types/BidderAddresses';
import { BiddingInfo } from '@/types/BiddingInfo';
import { CatalogRegistrationLegacyServerResponse } from '@/types/CatalogRegistration';
import { TypedActionWithPayload, TypedActionWithPayloadMetaActionTime } from '@/types/redux';
import ms from 'ms';

export type UserData = {
    ANMS: number;
    preferredCurrency: string;
    // premiumBidder is not used, used to be for ACH, but now it is unused - blake 2021-06-28
    upcomingBids?: number;
} & BidderProfile;

export const emptyUserData: UserData = {
    acceptedTermsOfService: 'unknown',
    address: '',
    address2: '',
    addresses: [],
    addressId: 0,
    ANMS: 0,
    approvalId: 0,
    bidderId: -1,
    city: '',
    country: '',
    countryCode: '',
    createdDate: '',
    creditCardValidated: false,
    email: '',
    firstName: '',
    hasEverRegisteredForAuction: false,
    lastName: '',
    latitude: null,
    levelOneScore: 0,
    longitude: null,
    mobileCarrier: 0,
    mobileNumber: '',
    mobileVerified: false,
    phoneNumber: '',
    phoneNumber2: '',
    preferredCurrency: 'USD',
    premiumBidder: false,
    profileComplete: false,
    recentBidWeightPercentile: 0,
    resellerId: '',
    signupClient: '',
    signupClientVersion: '',
    standing: '',
    state: '',
    upcomingBids: 0,
    username: '',
    verifiedMinimumBidLimit: false,
    zip: '',
};

export type UserSlice = {
    loaded: number;
    loading: boolean;
    mobile: string;
    token: string;
    userData: UserData;
};

export const defaultUserSlice: UserSlice = {
    loaded: 0,
    loading: false,
    mobile: '',
    token: '',
    userData: emptyUserData,
};

export const USER_SLICE_CACHE_TIME = ms('30m');

// disables sort key for the enum to better represent completion status in the type definition
/* eslint-disable typescript-sort-keys/string-enum */
export enum UserRegistrationStatus {
    Unregistered = 'unregistered',
    Partially = 'PartiallyRegisteredBidder',
    Registered = 'bidder',
}
/* eslint-enable typescript-sort-keys/string-enum */

export const noncontiguousStates = [
    'Hawaii',
    'HI',
    'Alaska',
    'AK',
    'American Samoa',
    'AS',
    'Virgin Islands',
    'VI',
    'Northern Mariana Islands',
    'MP',
    'Guam',
    'GU',
    'Puerto Rico',
    'PR',
];

export type FetchBidderDataResponsePayload = {
    annualNMS: number;
    bidder: BidderProfile;
    biddingInfos: BiddingInfo[];
    catalogRegistration: CatalogRegistrationLegacyServerResponse[];
    followedSellers: number[];
    resellerType: number;
    savedItems: number[];
    savedItemsTime: {
        itemId: string;
        saveTs: number;
    }[];
    searchExclusions: {
        houseIds: number[];
    };
};

export type FetchBidderDataResponse = {
    data: FetchBidderDataResponsePayload;
    message: string;
    success: boolean;
};

export const EmptyBidderProfileDataResponse: FetchBidderDataResponse = {
    data: {
        annualNMS: 1200,
        bidder: emptyUserData,
        biddingInfos: [],
        catalogRegistration: [],
        followedSellers: [],
        resellerType: 2,
        savedItems: [],
        savedItemsTime: [],
        searchExclusions: {
            houseIds: [],
        },
    },
    message: null,
    success: true,
};

export type FetchBidderDataParams = {
    authToken: string;
    deployment: string;
};

export type FetchBidderDataIfNeededParams = {
    authToken?: string;
    force?: boolean;
};

/**
 * @deprecated if using redux-toolkit's createSlice, use fetchBidderData.fulfilled
 * @see fetchBidderData
 */
export const LOAD_BIDDER_DATA_SUCCESS = 'la/domain/bidderData/LOAD_SUCCESS';
/**
 * @deprecated if using redux-toolkit's createSlice, use fetchBidderData.fulfilled
 * @see fetchBidderData
 */
export type LoadBidderDataSuccessAction = TypedActionWithPayloadMetaActionTime<
    typeof LOAD_BIDDER_DATA_SUCCESS,
    FetchBidderDataResponse['data']
>;
/**
 * @deprecated if using redux-toolkit's createSlice, use fetchBidderData.rejected
 * @see fetchBidderData
 */
export const LOAD_BIDDER_DATA_FAIL = 'la/domain/bidderData/LOAD_FAIL';
/**
 * @deprecated if using redux-toolkit's createSlice, use fetchBidderData.rejected
 * @see fetchBidderData
 */
export type LoadBidderDataFailAction = TypedActionWithPayload<typeof LOAD_BIDDER_DATA_FAIL, any, { token: string }>;

export type GeoCoordinates = {
    latitude: number;
    longitude: number;
} | null;

export type AcceptedTermsOfService = 'accepted' | 'unknown' | 'withdrawn';

export type BidderProfile = {
    acceptedTermsOfService: AcceptedTermsOfService;
    address: string;
    address2: string;
    addressId: number;
    addresses: BidderAddress[];
    approvalId: number;
    bidderId: number;
    city: string;
    country: string;
    countryCode: string;
    createdDate: string;
    creditCardValidated: boolean;
    email: string;
    firstName: string;
    hasEverRegisteredForAuction: boolean;
    lastName: string;
    latitude: string | null;
    levelOneScore: number;
    longitude: string | null;
    mobileCarrier: number;
    mobileNumber: string;
    mobileVerified: boolean;
    phoneNumber: string;
    phoneNumber2: string;
    premiumBidder: boolean;
    profileComplete: boolean;
    recentBidWeightPercentile: number;
    resellerId: string;
    signupClient: string;
    signupClientVersion: string;
    standing: string;
    state: string;
    username: string;
    verifiedMinimumBidLimit?: boolean;
    zip: string;
};

export type BidderProfileDataResponsePayload = FetchBidderDataResponsePayload & {
    // mainhostLogin exists on the api but is unused in bidder-react
    mainhostLogin: {
        '5Xq_name': string;
        '5Xq_nuid': string;
        '5Xq_nverify': string;
    };
    token: string;
};

export type BidderProfileDataResponse = {
    data: BidderProfileDataResponsePayload;
    message: string;
    success: boolean;
};
