export const GET_CERT_CAPTURE_TOKEN_FAIL = 'la/ui/certCaptureToken/FAIL';
export const GET_CERT_CAPTURE_TOKEN_REQUEST = 'la/ui/certCaptureToken/REQUEST';
export const GET_CERT_CAPTURE_TOKEN_SUCCESS = 'la/ui/certCaptureToken/SUCCESS';

export const GET_CERTIFICATES_FAIL = 'la/ui/certificates/FAIL';
export const GET_CERTIFICATES_REQUEST = 'la/ui/certificates/REQUEST';
export const GET_CERTIFICATES_SUCCESS = 'la/ui/certificates/SUCCESS';

export const DELETE_CERTIFICATE_FAIL = 'la/ui/deleteCertificate/FAIL';
export const DELETE_CERTIFICATE_REQUEST = 'la/ui/deleteCertificate/REQUEST';
export const DELETE_CERTIFICATE_SUCCESS = 'la/ui/deleteCertificate/SUCCESS';
