import { ApiHosts, handleResponseWithNon200Errors, makePost, makePut } from '@/redux/api/helpers';
import { BidderAddress } from '@/types/BidderAddresses';

export type UpdateProfileRequest = {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    phoneNumber?: string;
    zipcode: string;
};

export type UpdateProfileWithAddressRequest = {
    address: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    organizationName: string;
    phoneNumber?: string;
    state: string;
    suggested?: boolean;
    userVerified?: boolean;
    zipcode: string;
};

export type ProfileSuggestedAddress = {
    address1: string;
    address2: string;
    city: string;
    country: string;
    countryCode: string;
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    phoneNumber?: string;
    state: string;
    zipcode: string;
};

/**
 * @category User API
 * @see PostUpdateProfile
 */
/**
 * @category User API
 * @see postUpdateProfile
 */
export type PostUpdateProfileResponse = {
    error: boolean;
    payload: UpdateProfileWithAddressRequest & {
        suggestedAddress: ProfileSuggestedAddress;
    };
};
/**
 * @category User API
 * @see postUpdateProfile
 */
type PostUpdateProfileParams = {
    authToken: string;
    deployment: string;
    profile: UpdateProfileRequest;
};

/**
 * POST to Flynn user API service to update Bidder profile data
 * @function postUpdateProfile
 * @category User API
 * @param {PostUpdateProfileParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/updateprofile
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/5zbu5t/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnUpdateBidderProfileV2
 */
export const postUpdateProfile = ({ authToken, deployment, profile }: PostUpdateProfileParams) =>
    new Promise<PostUpdateProfileResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'updateprofile',
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
        });

        // TODO: Flynn wants some fields in a different format, we need to get this standardized across all apps
        const body: any = {
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            mobileNumber: profile.mobile,
            // phoneNumber: '',
            zip: profile.zipcode,
        };

        request.send(body);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, request, resolve, response }));
    });

export type PostAddBidderAddressParams = {
    address: BidderAddress;
    authToken: string;
    bidderID: number;
    deployment: string;
    verify?: boolean;
};

/**
 * @category User API
 * @see postAddBidderAddress
 */
export type PostAddBidderAddressResponse = {
    payload: BidderAddress[] | { suggestedAddress: BidderAddress };
};

/**
 * POST to Flynn user API service to Add Bidder profile data
 * @function postAddBidderAddress
 * @category User API
 * @param {PostAddBidderAddressParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/{bidderID}/address
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/4u2rza/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnAddBidderAddress
 */
export const postAddBidderAddress = ({
    address,
    authToken,
    bidderID,
    deployment,
    verify = false,
}: PostAddBidderAddressParams) =>
    new Promise<PostAddBidderAddressResponse>((resolve, reject) => {
        const queryParams = verify ? { verify } : undefined;
        const request = makePost({
            apiPath: `${bidderID}/address`,
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
            queryParams,
        });

        request.send(address);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, request, resolve, response }));
    });

export type PostUpdateBidderAddressParams = {
    address: BidderAddress;
    authToken: string;
    bidderID: number;
    deployment: string;
    verify?: boolean;
};

/**
 * @category User API
 * @see postUpdateBidderAddress
 */
type PostUpdateBidderAddressResponse = {
    payload: BidderAddress[] | { suggestedAddress: BidderAddress };
};

/**
 * POST to Flynn user API service to update Bidder profile data
 * @function postUpdateBidderAddress
 * @category User API
 * @param {PostUpdateBidderAddressParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/{bidderID}/address
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/4u2rza/methods/PUT
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnUpdateBidderAddress
 */
export const postUpdateBidderAddress = ({
    address,
    authToken,
    bidderID,
    deployment,
    verify = false,
}: PostUpdateBidderAddressParams) =>
    new Promise<PostUpdateBidderAddressResponse>((resolve, reject) => {
        const queryParams = verify ? { verify } : undefined;
        const request = makePut({
            apiPath: `${bidderID}/address`,
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
            queryParams,
        });

        request.send(address);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, request, resolve, response }));
    });

export type PostAddBidderAddressV2Params = {
    address: BidderAddress;
    authToken: string;
    bidderId: number;
    deployment: string;
    verify?: boolean;
};

/**
 * @category User API
 * @see postAddBidderAddressV2
 */
type PostAddBidderAddressV2Response = {
    payload: {
        bidderAddresses: BidderAddress[];
        suggestedAddress: BidderAddress;
        suggestionProvided: boolean;
    };
};

/**
 * POST to Flynn user API service to Add Bidder profile data
 * @function postAddBidderAddressV2
 * @category User API
 * @param {PostAddBidderAddressV2Params}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/{bidderID}/v2/address
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/4u2rza/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnAddBidderAddressV2
 */
export const postAddBidderAddressV2 = ({
    address,
    authToken,
    bidderId,
    deployment,
    verify = false,
}: PostAddBidderAddressV2Params) =>
    new Promise<PostAddBidderAddressV2Response>((resolve, reject) => {
        const queryParams = verify ? { verify } : undefined;
        const request = makePost({
            apiPath: `${bidderId}/v2/address`,
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
            queryParams,
        });

        request.send(address);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, request, resolve, response }));
    });

export type PostUpdateBidderAddressV2Params = {
    address: BidderAddress;
    authToken: string;
    bidderID: number;
    deployment: string;
    verify?: boolean;
};

/**
 * @category User API
 * @see postUpdateBidderAddressV2
 */
type PostUpdateBidderAddressV2Response = {
    payload: { address: BidderAddress[]; suggestedAddress: BidderAddress; suggestionProvided: boolean };
};

/**
 * POST to Flynn user API service to update Bidder profile data
 * @function postUpdateBidderAddressV2
 * @category User API
 * @param {PostUpdateBidderAddressV2Params}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/{bidderID}/v2/address
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/4u2rza/methods/PUT
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnUpdateBidderAddressV2
 */
export const postUpdateBidderAddressV2 = ({
    address,
    authToken,
    bidderID,
    deployment,
    verify = false,
}: PostUpdateBidderAddressV2Params) =>
    new Promise<PostUpdateBidderAddressV2Response>((resolve, reject) => {
        const queryParams = verify ? { verify } : undefined;
        const request = makePut({
            apiPath: `${bidderID}/v2/address`,
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
            queryParams,
        });

        request.send(address);
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, request, resolve, response }));
    });
