import type { BidSource } from '@/types/BidSource';
import type { CurrentLotStatus } from '@/types/LiveAuctionState';
import type { PubSubProvider } from '@/types/PubSubProvider';
import type { TypedActionWithPayload } from '@/types/redux';

export const LIVE_ASK_CHANGED = 'LIVE_ASK_CHANGED';
export type LIVE_ASK_CHANGED_ACTION = TypedActionWithPayload<
    typeof LIVE_ASK_CHANGED,
    {
        ask: number;
        catalogId: number;
        created: number;
        currency: string;
        increment: number;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
        serverCreatedTime: string;
    }
>;

export const LIVE_AUCTION_ENDED = 'LIVE_AUCTION_ENDED';
export type LIVE_AUCTION_ENDED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_ENDED,
    { catalogId: number; created: number }
>;

export const LIVE_AUCTION_LOADED = 'LIVE_AUCTION_LOADED';
export type LIVE_AUCTION_LOADED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_LOADED,
    { catalogId: number; created: number; serverId: number }
>;

export const LIVE_AUCTION_MOVED = 'LIVE_AUCTION_MOVED';
export type LIVE_AUCTION_MOVED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_MOVED,
    { catalogId: number; created: number }
>;

export const LIVE_AUCTION_OPENED = 'LIVE_AUCTION_OPENED';
export type LIVE_AUCTION_OPENED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_OPENED,
    { catalogId: number; created: number; serverId: number }
>;

export const LIVE_AUCTION_PAUSED = 'LIVE_AUCTION_PAUSED';
export type LIVE_AUCTION_PAUSED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_PAUSED,
    { catalogId: number; created: number; serverCreatedTime: string }
>;

export const LIVE_AUCTION_RESUMED = 'LIVE_AUCTION_RESUMED';
export type LIVE_AUCTION_RESUMED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_RESUMED,
    { catalogId: number; created: number; serverCreatedTime: string }
>;

export const LIVE_AUCTION_STARTED = 'LIVE_AUCTION_STARTED';
export type LIVE_AUCTION_STARTED_ACTION = TypedActionWithPayload<
    typeof LIVE_AUCTION_STARTED,
    { catalogId: number; created: number }
>;

export const LIVE_BID_ACCEPTED = 'LIVE_BID_ACCEPTED';
export type LiveBidAcceptedAction = TypedActionWithPayload<
    typeof LIVE_BID_ACCEPTED,
    {
        amount: number;
        ask: number;
        bidCount: number;
        bidLimitBalance: number;
        bidderId: number;
        bidderOutbid: boolean;
        catalogId: number;
        created: number;
        currency: string;
        endTime: string;
        isCurrentBidder: boolean;
        isExtended: boolean;
        isReserveMet: boolean;
        isTimedPlusAuction?: boolean;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
        myBid: boolean;
        paddleNumber: any;
        previousBidAmount: number;
        serverCreatedTime: string;
        source: BidSource;
    }
>;

export const LIVE_BID_PLACED = 'LIVE_BID_PLACED';
export type LiveBidPlacedAction = TypedActionWithPayload<
    typeof LIVE_BID_PLACED,
    {
        amount: number;
        bidSource: BidSource;
        bidderId: number;
        catalogId: number;
        created: string;
        currentUserPlacedBid: boolean;
        lotId: number;
    }
>;

export const LIVE_BID_RETRACTED = 'LIVE_BID_RETRACTED';
export type LiveBidRetractedAction = TypedActionWithPayload<
    typeof LIVE_BID_RETRACTED,
    {
        amount: number;
        ask: number;
        bidCount: number;
        bidderId: number;
        catalogId: number;
        created: number;
        currency: string;
        currentBidderId: number;
        endTime: string;
        isExtended: boolean;
        isReserveMet: boolean;
        isTimedPlusAuction?: boolean;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
        myBid: boolean;
        serverCreatedTime: string;
        source: BidSource;
    }
>;

export const LIVE_LOT_CLOSED = 'LIVE_LOT_CLOSED';
export type LIVE_LOT_CLOSED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_CLOSED,
    {
        catalogId: number;
        created: number;
        itemId: number;
        lotNumber: string;
        soldAssignedId: number;
    }
>;

export const LIVE_LOT_PASSED = 'LIVE_LOT_PASSED';
export type LIVE_LOT_PASSED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_PASSED,
    {
        catalogId: number;
        closedLotsCount: number;
        created: number;
        itemId: number;
        lotNumber: string;
        serverCreatedTime: string;
    }
>;

export const LIVE_LOT_REOPENED = 'LIVE_LOT_REOPENED';
export type LIVE_LOT_REOPENED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_REOPENED,
    {
        askPrice: number;
        catalogId: number;
        created: number;
        increment: number;
        itemId: number;
        itemIndex: number;
        leadingBid: number;
        leadingBidder: number;
        lotNumber: string;
        myBid: boolean;
        serverCreatedTime: string;
    }
>;

export const LIVE_LOT_SKIPPED = 'LIVE_LOT_SKIPPED';
export type LIVE_LOT_SKIPPED_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_SKIPPED,
    {
        catalogId: number;
        created: number;
        itemId: number;
        lotNumber: string;
        newLotNumber: string;
        serverCreatedTime: string;
    }
>;

export const LIVE_LOT_SOLD = 'LIVE_LOT_SOLD';
export type LIVE_LOT_SOLD_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_SOLD,
    {
        amount: number;
        bidderId: number;
        catalogId: number;
        closedLotsCount: number;
        created: number;
        currency: string;
        itemId: number;
        lotNumber: string;
        myBid: boolean;
        paddleNumber: string;
        serverCreatedTime: string;
    }
>;

export const LIVE_LOT_UNSOLD = 'LIVE_LOT_UNSOLD';
export type LIVE_LOT_UNSOLD_ACTION = TypedActionWithPayload<
    typeof LIVE_LOT_UNSOLD,
    {
        catalogId: number;
        created: number;
        itemId: number;
        lotNumber: string;
        serverCreatedTime: string;
    }
>;

export const LIVE_MISSIVE = 'LIVE_MISSIVE';
export type LIVE_MISSIVE_ACTION = TypedActionWithPayload<
    typeof LIVE_MISSIVE,
    {
        catalogId: number;
        created: number;
        isTimed: boolean;
        itemId: number;
        missiveText: string;
        serverCreatedTime: string;
    }
>;

export const LIVE_NETWORK_DISCONNECTED = 'LIVE_NETWORK_DISCONNECTED';
export type LIVE_NETWORK_DISCONNECTED_ACTION = TypedActionWithPayload<
    typeof LIVE_NETWORK_DISCONNECTED,
    {
        catalogId: number;
        provider: PubSubProvider;
    }
>;

export const LIVE_NETWORK_RECONNECTED = 'LIVE_NETWORK_RECONNECTED';
export type LIVE_NETWORK_RECONNECTED_ACTION = TypedActionWithPayload<
    typeof LIVE_NETWORK_RECONNECTED,
    {
        catalogId: number;
        provider: PubSubProvider;
    }
>;

export const LIVE_NEXT_LOT_LOADED = 'LIVE_NEXT_LOT_LOADED';
export type LIVE_NEXT_LOT_LOADED_ACTION = TypedActionWithPayload<
    typeof LIVE_NEXT_LOT_LOADED,
    {
        askPrice: number;
        catalogId: number;
        created: number;
        currency: string;
        description: string;
        highEstimate: number;
        index: number;
        itemId: number;
        itemIndex: number;
        lotNumber: string;
        lotTitle: string;
        lowEstimate: number;
        serverCreatedTime: string;
        status: CurrentLotStatus;
    }
>;

export const LIVE_NEXT_LOT_OPENED = 'LIVE_NEXT_LOT_OPENED';

export const LIVE_UPDATE_CATALOG_OCCUPANCY = 'LIVE_UPDATE_CATALOG_OCCUPANCY';
export type LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION = TypedActionWithPayload<
    typeof LIVE_UPDATE_CATALOG_OCCUPANCY,
    {
        bidderCount: number;
        catalogId: number;
    }
>;

export const LIVE_UPDATE_TIMESTAMP = 'LIVE_UPDATE_TIMESTAMP';
export type LIVE_UPDATE_TIMESTAMP_ACTION = TypedActionWithPayload<
    typeof LIVE_UPDATE_TIMESTAMP,
    {
        catalogId: number;
        timestamp: number;
    }
>;
