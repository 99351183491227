import { CompleteAccountModalContext, openCompleteAccountSignUpModal, openOnTheHuntModal } from '@/redux/modules/modal';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { EventContext } from '../analytics';
import { formatHyphenatedDateOnly } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { shouldPopModal } from '@/redux/modules/autoPopModal/autoPopModal.selectors';

export const onTheHuntModalPageRoutes = ['/c/', '/item/', '/price-guide/', '/search/', '/price-result/'];

export const getIsOnTheHuntPage = (path: string) =>
    Boolean(onTheHuntModalPageRoutes.find((huntRoute) => path.includes(huntRoute))) &&
    !path.includes('/catalog/search/');

export const autoOpenSignUpModal = createAsyncThunk<string, string>(
    'la/domain/autoPopModal/autoOpenSignUpModal',
    (route, { dispatch, getState }) => {
        const state = getState();
        if (!shouldPopModal(state)) {
            return '';
        }
        const isOnTheHuntPage = getIsOnTheHuntPage(route);
        if (isOnTheHuntPage) {
            dispatch(openOnTheHuntModal());
        } else {
            dispatch(
                openCompleteAccountSignUpModal({
                    eventContext: EventContext.autoPopModal,
                    id: 0,
                    type: CompleteAccountModalContext.Default,
                })
            );
        }
        return formatHyphenatedDateOnly(Date.now());
    }
);
