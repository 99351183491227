export type AutoPopModalSlice = {
    readonly dateLastShown: string;
    readonly disableModalForTesting: boolean;
    readonly hasLoggedInBefore: boolean;
};

export const defaultAutoPopModalSlice: AutoPopModalSlice = {
    dateLastShown: '',
    disableModalForTesting: false,
    hasLoggedInBefore: false,
};
