import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    CREATE_ACCOUNT_SUCCESS,
    CreateAccountPayload,
    CreateAccountSuccessAction,
} from '@/redux/modules/account/createAccount/createAccount.types';
import { fetchBidderDataIfNeeded } from '@/redux/modules/account/user/user.actions';
import { getDeployment } from '@/redux/modules/config';
import { getRegisterAccountAnalytics, trackUserAuthenticated } from '@/redux/modules/analytics';
import { openCompleteAccountSignUpModal } from '@/redux/modules/modal';
import { postCreateAccount } from '@/redux/modules/account/createAccount/createAccount.api';
import { saveExistingBidderEmail } from '@/redux/modules/bidder/exists/bidderExists.actions';

/**
 * Send account information to API to attempt user creation
 * @param payload {CreateAccountPayload}
 * @param payload.emailAddress User's email address
 * @param payload.agree Did the user agree to the terms, privacy policy, and cookies
 * @param payload.attributionData
 * @param payload.shouldOpenModal Should the modals open?
 * @param payload.onSuccessCallback - OPTIONAL on a successful account creation, this function will be called
 * @param payload.onExistingAccount What should be done if the user already has an account under their email
 */
export const submitCreateAccount = createAsyncThunk<string, CreateAccountPayload, AsyncThunkConfigWithRejectValue>(
    'la/ui/createAccount/submitCreateAccount',
    async (
        {
            agree,
            attributionData,
            deviceId,
            emailAddress,
            onExistingAccount,
            onSuccessCallback,
            pageName,
            sessionId,
            shouldOpenModal = true,
            url,
            visitorCode,
        },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const state = getState();
            const deployment = getDeployment(state);

            const { payload } = await postCreateAccount({
                agree,
                deployment,
                deviceId,
                emailAddress,
                pageName,
                sessionId,
                url,
                visitorCode,
            });
            const { bidderId, createToken } = payload;

            const analytics = getRegisterAccountAnalytics(getState(), bidderId, attributionData);

            const successAction: CreateAccountSuccessAction = {
                error: false,
                meta: {
                    actionTime: Date.now(),
                    analytics,
                },
                payload: createToken,
                type: CREATE_ACCOUNT_SUCCESS,
            };
            dispatch(successAction);

            let promises: Promise<unknown>[] = [dispatch(trackUserAuthenticated())];
            if (shouldOpenModal) {
                dispatch(openCompleteAccountSignUpModal());
            }

            promises.push(
                dispatch(
                    fetchBidderDataIfNeeded({
                        authToken: createToken,
                        force: true,
                    })
                )
            );

            await Promise.all(promises);

            onSuccessCallback?.();

            return createToken;
        } catch (originalError) {
            const error = typeof originalError === 'string' ? new Error(originalError) : (originalError as Error);
            if (error.message.toLowerCase().includes('user already exists')) {
                await dispatch(saveExistingBidderEmail(emailAddress));
                onExistingAccount?.();
            }
            return rejectWithValue(error);
        }
    }
);
