import { ApiHosts, handleResponseOld, makeDelete, makeGet } from '@/redux/api/helpers';

/**
 * @category User API
 * @see deleteAccount
 */
type deleteAccountResponse = {
    error: boolean;
    payload: string;
};

/**
 * @category User API
 * @see deleteAccount
 */
type deleteAccountParams = {
    bidderId: number;
    deployment: string;
    token: string;
};

/**
 * DELETE to Flynn user API service to delete a user account
 * @function deleteAccount
 * @category User API
 * @param {deleteAccountParams}
 * @see {{host-flynn}}/user/{{bidderId}}/delete-account?token={{token-from-link}}
 */
export const deleteAccount = ({ bidderId, deployment, token }: deleteAccountParams) =>
    new Promise<deleteAccountResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `${bidderId}/delete-account`,
            deployment,
            path: ApiHosts.FlynnUser,
            queryParams: { token },
            useCacheKey: false,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * GET to Flynn user API service to send a deletion email link
 * @function sendDeletionEmail
 * @category User API
 * @param {number} bidderId
 * @param {string} deployment
 */
export const sendDeletionEmail = (bidderId: number, deployment: string) =>
    new Promise((resolve, reject) => {
        const request = makeGet({
            apiPath: `${bidderId}/deletion-link`,
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
