export const LOAD_GEO_AREA_REQUEST = 'la/domain/geoArea/LOAD_GEO_AREA_REQUEST';
export const LOAD_GEO_AREA_SUCCESS = 'la/domain/geoArea/LOAD_GEO_AREA_SUCCESS';
export const LOAD_GEO_AREA_FAILURE = 'la/domain/geoArea/LOAD_GEO_AREA_FAILURE';

export const LOAD_RELATED_GEO_AREA_REQUEST = 'la/domain/geoArea/LOAD_RELATED_GEO_AREA_REQUEST';
export const LOAD_RELATED_GEO_AREA_SUCCESS = 'la/domain/geoArea/LOAD_RELATED_GEO_AREA_SUCCESS';
export const LOAD_RELATED_GEO_AREA_FAILURE = 'la/domain/geoArea/LOAD_RELATED_GEO_AREA_FAILURE';

export const LOAD_ALL_GEO_AREAS_REQUEST = 'la/domain/geoArea/LOAD_ALL_GEO_AREAS_REQUEST';
export const LOAD_ALL_GEO_AREAS_SUCCESS = 'la/domain/geoArea/LOAD_ALL_GEO_AREAS_SUCCESS';
export const LOAD_ALL_GEO_AREAS_FAILURE = 'la/domain/geoArea/LOAD_ALL_GEO_AREAS_FAILURE';

export const LOAD_RELATED_GEO_AREA_BY_LOCATION_REQUEST = 'la/domain/geoArea/LOAD_RELATED_GEO_AREA_BY_LOCATION_REQUEST';
export const LOAD_RELATED_GEO_AREA_BY_LOCATION_SUCCESS = 'la/domain/geoArea/LOAD_RELATED_GEO_AREA_BY_LOCATION_SUCCESS';
export const LOAD_RELATED_GEO_AREA_BY_LOCATION_FAILURE = 'la/domain/geoArea/LOAD_RELATED_GEO_AREA_BY_LOCATION_FAILURE';
