import { Location } from 'react-router-dom';

export type AbsenteeBidSlice = {
    bidAmount: number | null;
    bidPlaced: boolean;
    error: boolean;
    isCurrentHighBid: boolean;
    itemId: number | null;
    submitted: boolean;
    success: boolean;
};

export const defaultAbsenteeBidSlice: AbsenteeBidSlice = {
    bidAmount: null,
    bidPlaced: false,
    error: false,
    isCurrentHighBid: false,
    itemId: null,
    submitted: false,
    success: false,
};

export type AbsenteeBidDetails = {
    bidAmount: number;
    catalogId: number;
    client?: 'React';
    deviceType?: 'Mobile' | 'Desktop';
    itemId: number;
    minimumBidLimit?: boolean;
    raisedAbsenteeBid?: boolean;
};

export type SubmitAbsenteeBidParams = {
    absenteeBidDetails: AbsenteeBidDetails;
    location: Location;
};

/**
 * @category Mainhost API
 * @see postPlaceAbsenteeBid
 */
export type PostPlaceAbsenteeBidParams = {
    authToken: string;
    deployment: string;
    details: AbsenteeBidDetails;
};

export type PostPlaceAbsenteeBidResponse = {
    currentBid: number;
    isCurrentHighBid: boolean;
    isReserveMet: boolean;
    leadingBid: number;
    message: string;
    success: boolean;
};

export type SubmitAbsenteeBidPayload = {
    meta: {
        actionTime: number;
        analytics: any;
        itemId: number;
    };
    payload: PostPlaceAbsenteeBidResponse;
};
