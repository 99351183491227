import { SearchAuctionFacet, SearchAuctionResult } from '@/redux/modules/auctionCalendar/auctionCalendar.api';

export type AuctionCalendarSlice = {
    catalogIds: number[];
    catalogsTodayCount: number;
    error: boolean;
    facets: SearchAuctionFacet[];
    submitted: boolean;
    success: boolean;
    totalFound: number;
};

export const defaultAuctionCalendarSlice: AuctionCalendarSlice = {
    catalogIds: [],
    catalogsTodayCount: 0,
    error: false,
    facets: [],
    submitted: false,
    success: false,
    totalFound: 0,
};

export type AuctionCalendarPayload = {
    count: number;
    facets: SearchAuctionFacet[];
    results: SearchAuctionResult[];
};

export type TodaysCatalogCount = { count: number };
export type TodaysCatalogCountApiResponse = [TodaysCatalogCount];
