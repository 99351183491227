import { BankAccount } from '@liveauctioneers/caterwaul-components/types/BankAccount';
import { TypedActionWithPayload } from '@/types/redux';

export const LOAD_BANK_ACCOUNTS_FAIL = 'LOAD_BANK_ACCOUNTS_FAIL';
export type LOAD_BANK_ACCOUNTS_FAIL_ACTION = TypedActionWithPayload<typeof LOAD_BANK_ACCOUNTS_FAIL, string>;

export const LOAD_BANK_ACCOUNTS_REQUEST = 'LOAD_BANK_ACCOUNTS_REQUEST';
export type LOAD_BANK_ACCOUNTS_REQUEST_ACTION = TypedActionWithPayload<typeof LOAD_BANK_ACCOUNTS_REQUEST>;

export const LOAD_BANK_ACCOUNTS_SUCCESS = 'LOAD_BANK_ACCOUNTS_SUCCESS';
export type LOAD_BANK_ACCOUNTS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_BANK_ACCOUNTS_SUCCESS,
    BankAccount[]
>;

export const DELETE_BANK_ACCOUNT_FAIL = 'DELETE_BANK_ACCOUNT_FAIL';
export type DELETE_BANK_ACCOUNT_FAIL_ACTION = TypedActionWithPayload<typeof DELETE_BANK_ACCOUNT_FAIL, string>;

export const DELETE_BANK_ACCOUNT_REQUEST = 'DELETE_BANK_ACCOUNT_REQUEST';
export type DELETE_BANK_ACCOUNT_REQUEST_ACTION = TypedActionWithPayload<typeof DELETE_BANK_ACCOUNT_REQUEST>;

export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS';
export type DELETE_BANK_ACCOUNT_SUCCESS_ACTION = TypedActionWithPayload<typeof DELETE_BANK_ACCOUNT_SUCCESS>;

export const FETCH_PLAID_LINK_TOKEN_FAIL = 'FETCH_PLAID_LINK_TOKEN_FAIL';
export type FETCH_PLAID_LINK_TOKEN_FAIL_ACTION = TypedActionWithPayload<typeof FETCH_PLAID_LINK_TOKEN_FAIL, string>;

export const FETCH_PLAID_LINK_TOKEN_REQUEST = 'FETCH_PLAID_LINK_TOKEN_REQUEST';
export type FETCH_PLAID_LINK_TOKEN_REQUEST_ACTION = TypedActionWithPayload<typeof FETCH_PLAID_LINK_TOKEN_REQUEST>;

export const FETCH_PLAID_LINK_TOKEN_SUCCESS = 'FETCH_PLAID_LINK_TOKEN_SUCCESS';
export type FETCH_PLAID_LINK_TOKEN_SUCCESS_ACTION = TypedActionWithPayload<
    typeof FETCH_PLAID_LINK_TOKEN_SUCCESS,
    string,
    { accessToken: string }
>;

export const SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL = 'SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL';
export type SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL_ACTION = TypedActionWithPayload<
    typeof SUBMIT_CREATE_PLAID_BANK_ACCOUNT_FAIL,
    string
>;

export const SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST = 'SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST';
export type SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST_ACTION = TypedActionWithPayload<
    typeof SUBMIT_CREATE_PLAID_BANK_ACCOUNT_REQUEST
>;

export const SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS = 'SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS';
export type SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS_ACTION = TypedActionWithPayload<
    typeof SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS,
    BankAccount,
    { accessToken?: string }
>;
