import { createSelector } from '@reduxjs/toolkit';
import {
    defaultUpdateAccountSlice,
    SET_ADDRESS_VALIDATION_ERROR,
    UPDATE_ACCOUNT_FAIL,
    UPDATE_ACCOUNT_REQUEST,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_SUGGESTION,
    UPDATE_ACCOUNT_TOAST_MESSAGE,
    UpdateAccountSlice,
    UpdateAccountSuggestionAction,
} from './updateAccount.types';
import { emptyBidderAddress } from '@/types/BidderAddresses';
import { GlobalState } from '@/redux/store';
import { OPEN_MODAL } from '@/redux/modules/actions';

/* reducer */
const pullMessages = (messages: string | Array<string>) => {
    if (typeof messages === 'string') {
        return messages;
    }

    return Object.keys(messages)
        .map((key) => messages[key])
        .join(' ');
};

export default function reducer(state = defaultUpdateAccountSlice, action: any = {}): UpdateAccountSlice {
    switch (action.type) {
        case UPDATE_ACCOUNT_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: pullMessages(action.payload),
                submitted: false,
                success: false,
                suggestion: null,
            };
        case UPDATE_ACCOUNT_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case UPDATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                error: false,
                errorMessage: null,
                submitted: false,
                success: true,
            };
        case UPDATE_ACCOUNT_SUGGESTION:
            const suggestionAction = action as UpdateAccountSuggestionAction;
            return {
                ...state,
                error: false,
                errorMessage: null,
                original: suggestionAction.payload?.payload?.originalAddress || emptyBidderAddress,
                submitted: false,
                success: true,
                suggestion: suggestionAction.payload?.payload?.suggestedAddress,
            };
        case UPDATE_ACCOUNT_TOAST_MESSAGE:
            return {
                ...state,
                toastMessage: action.payload,
            };
        case SET_ADDRESS_VALIDATION_ERROR:
            return {
                ...state,
                addressValidationError: action.payload,
            };

        case OPEN_MODAL:
            return {
                ...defaultUpdateAccountSlice,
                original: state.original ?? defaultUpdateAccountSlice.original,
                suggestion: state.suggestion ?? defaultUpdateAccountSlice.suggestion,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

export const getUiUpdateAccount = createSelector(stateSelector, (state) => state.updateAccount);
export const getAddressExternalValidationError = createSelector(
    getUiUpdateAccount,
    (state) => state.addressValidationError
);
export const getUpdateAccountToastMessage = createSelector(getUiUpdateAccount, (state) => state.toastMessage);
export const getUpdateAccountLoading = createSelector(getUiUpdateAccount, (state) => state.submitted && !state.success);
export const getUpdateAccountHasLoaded = createSelector(getUiUpdateAccount, (state) => state.success);
export const getUpdateAccountHasError = createSelector(getUiUpdateAccount, ({ error }) => error);
export const getStoredAddressSuggestion = createSelector(getUiUpdateAccount, (state) => state.suggestion);
export const getStoredOriginalAddress = createSelector([getUiUpdateAccount], ({ original }) => original);
