import { combineActions, handleActions } from 'redux-actions';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import { DISMISS_MODAL, SAVE_SEARCH_REQUEST, SAVE_SEARCH_SUCCESS } from './actions';
import { GlobalState } from '@/redux/store';
import { PayloadlessAction } from '@/types/redux';

export const RESET_ADD_SEARCH_ALERT = 'la/ui/addSearchAlert/RESET';
export type RESET_ADD_SEARCH_ALERT_ACTION = PayloadlessAction<typeof RESET_ADD_SEARCH_ALERT>;

export interface AddSearchAlertSlice {
    readonly submitted: boolean;
    readonly success: boolean;
}

export const defaultAddSearchAlertSlice: AddSearchAlertSlice = {
    submitted: false,
    success: false,
};

export const reducer: Reducer<AddSearchAlertSlice> = handleActions(
    {
        [combineActions(DISMISS_MODAL, RESET_ADD_SEARCH_ALERT)]: (): AddSearchAlertSlice => {
            return defaultAddSearchAlertSlice;
        },
        [SAVE_SEARCH_REQUEST]: (state: AddSearchAlertSlice) => ({
            ...state,
            submitted: true,
            success: false,
        }),
        [SAVE_SEARCH_SUCCESS]: (state: AddSearchAlertSlice) => ({
            ...state,
            submitted: false,
            success: true,
        }),
    },
    defaultAddSearchAlertSlice
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

export const addSearchAlertSelector = createSelector(stateSelector, (state) => state.addSearchAlert);

/* ACTION CREATORS */
export const setDefaultAddSearchAlertState = () => async (dispatch: any) => {
    const action: RESET_ADD_SEARCH_ALERT_ACTION = {
        type: RESET_ADD_SEARCH_ALERT,
    };
    return dispatch(action);
};
