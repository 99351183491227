import { createSlice } from '@reduxjs/toolkit';
import { defaultCreateAccountSlice } from '@/redux/modules/account/createAccount/createAccount.types';
import { submitCreateAccount } from '@/redux/modules/account/createAccount/createAccount.actions';

const createAccountSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(submitCreateAccount.pending, (slice) => {
            slice.error = null;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(submitCreateAccount.fulfilled, (slice) => {
            slice.error = null;
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(submitCreateAccount.rejected, (slice, { payload }) => {
            slice.error = payload;
            slice.submitted = false;
            slice.success = false;
        });
    },
    initialState: defaultCreateAccountSlice,
    name: 'createAccount',
    reducers: {},
});

export const { reducer: createAccountReducer } = createAccountSlice;
