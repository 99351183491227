import { PayloadlessAction, TypedActionWithPayload } from '@/types/redux';

export const COMPLETE_COMPLETE_ACCOUNT_MODAL = 'COMPLETE_COMPLETE_ACCOUNT_MODAL';
export const COMPLETE_CONFIRM_PASSWORD_MODAL = 'COMPLETE_CONFIRM_PASSWORD_MODAL';
export const COMPLETE_LOGIN_MODAL = 'COMPLETE_LOGIN_MODAL';
export const DISMISS_MODAL = 'DISMISS_MODAL';
export type DISMISS_MODAL_ACTION = PayloadlessAction<typeof DISMISS_MODAL>;
export const OPEN_ADD_CREDIT_CARD_MODAL = 'OPEN_ADD_CREDIT_CARD_MODAL';
export const OPEN_BID_LIMIT_EXCEEDED_MODAL = 'OPEN_BID_LIMIT_EXCEEDED_MODAL';
export const OPEN_BUY_NOW_INQUIRY_MODAL = 'OPEN_BUY_NOW_INQUIRY_MODAL';
export const OPEN_BUY_NOW_MODAL = 'OPEN_BUY_NOW_MODAL';
export const OPEN_CATALOG_ANNOUNCEMENT_MODAL = 'OPEN_CATALOG_ANNOUNCEMENT_MODAL';
export type OpenCatalogAnnouncementModalAction = TypedActionWithPayload<
    typeof OPEN_CATALOG_ANNOUNCEMENT_MODAL,
    { catalogId: number }
>;
export const OPEN_CHANGE_PASSWORD_MODAL = 'OPEN_CHANGE_PASSWORD_MODAL';
export const OPEN_CLASSIC_BIDDING_CONSOLE_MODAL = 'OPEN_CLASSIC_BIDDING_CONSOLE_MODAL';
export const OPEN_CLEAR_CACHE_MODAL = 'OPEN_CLEAR_CACHE_MODAL';
export const OPEN_COMPLETE_ACCOUNT_MODAL = 'OPEN_COMPLETE_ACCOUNT_MODAL';
export const OPEN_COMPLETE_ACCOUNT_SIGNUP_MODAL = 'OPEN_COMPLETE_ACCOUNT_SIGNUP_MODAL';
export const OPEN_CONFIRM_UNFOLLOW_SEARCH_MODAL = 'OPEN_CONFIRM_UNFOLLOW_SEARCH_MODAL';
export const OPEN_CONSIGNMENT_CONFIRM_MODAL = 'OPEN_CONSIGNMENT_CONFIRM_MODAL';
export const OPEN_DELETE_ACCOUNT_CONFIRMATION_MODAL = 'OPEN_DELETE_ACCOUNT_CONFIRMATION_MODAL';
export const OPEN_RESEND_CONFIRMATION_EMAIL_MODAL = 'OPEN_RESEND_CONFIRMATION_EMAIL_MODAL';
export const OPEN_DOWNLOAD_MOBILE_MODAL = 'OPEN_DOWNLOAD_MOBILE_MODAL';
export const OPEN_EXCLUDE_HOUSE_MODAL = 'OPEN_EXCLUDE_HOUSE_MODAL';
export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const OPEN_GET_HELP_MODAL = 'OPEN_GET_HELP_MODAL';
export type OpenGetHelpModalAction = TypedActionWithPayload<
    typeof OPEN_GET_HELP_MODAL,
    { invoiceId: number; invoiceStatus: string }
>;
export const OPEN_HEALTHCHECK_MODAL = 'OPEN_HEALTHCHECK_MODAL';
export const OPEN_INSTALL_FLASH_MODAL = 'OPEN_INSTALL_FLASH_MODAL';
export const OPEN_LIVE_BID_MODAL = 'OPEN_LIVE_BID_MODAL';
export const OPEN_LIVE_SHIPPING_DEADLINE_MODAL = 'OPEN_LIVE_SHIPPING_DEADLINE_MODAL';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_ON_THE_HUNT_MODAL = 'OPEN_ON_THE_HUNT_MODAL';
export const OPEN_PHONE_VERIFICATION_MODAL = 'OPEN_PHONE_VERIFICATION_MODAL';
export const OPEN_PLACE_ABSENTEE_BID = 'OPEN_PLACE_ABSENTEE_BID';
export type OpenAbsenteeBidModalAction = TypedActionWithPayload<
    typeof OPEN_PLACE_ABSENTEE_BID,
    { bidAmount?: number; isInstantAutopay: boolean; itemId: number }
>;
export const OPEN_PREFERENCES_MODAL = 'OPEN_PREFERENCES_MODAL';
export const OPEN_PURCHASE_PROTECTION_MODAL = 'OPEN_PURCHASE_PROTECTION_MODAL';
export const OPEN_REGISTER_FOR_CATALOG_MODAL = 'OPEN_REGISTER_FOR_CATALOG_MODAL';
export const OPEN_REPORT_REVIEW_MODAL = 'OPEN_REPORT_REVIEW_MODAL';
export const OPEN_RETRACT_BID_MODAL = 'OPEN_RETRACT_BID_MODAL';
export type OpenRetractBidModalAction = TypedActionWithPayload<typeof OPEN_RETRACT_BID_MODAL, { itemId: number }>;
export const OPEN_REVIEWS_MODAL = 'OPEN_REVIEWS_MODAL';
export const OPEN_SEND_SELLER_CONSIGNMENT_MESSAGE_MODAL = 'OPEN_SEND_SELLER_CONSIGNMENT_MESSAGE_MODAL';
export const OPEN_SEND_SELLER_MESSAGE_MODAL = 'OPEN_SEND_SELLER_MESSAGE_MODAL';
export const OPEN_SEND_SELLER_PHOTO_REQUEST_MODAL = 'OPEN_SEND_SELLER_PHOTO_REQUEST_MODAL';
export const OPEN_SHIPPING_AND_PAYMENT_MODAL = 'OPEN_SHIPPING_AND_PAYMENT_MODAL';
export const OPEN_SMS_CONFIRMATION_MODAL = 'OPEN_SMS_CONFIRMATION_MODAL';
export const OPEN_TAX_EXEMPT_MODAL = 'OPEN_TAX_EXEMPT_MODAL';
export const OPEN_WAITING_FOR_APPROVAL_MODAL = 'OPEN_WAITING_FOR_APPROVAL_MODAL';
export const SET_LOGIN_SIGNUP_TYPE = 'SET_LOGIN_SIGNUP_TYPE';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
