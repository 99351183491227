import type { ItemSummary } from '@/types/item/ItemSummary';
import type { TypedActionWithPayload } from '@/types/redux';

/* Action Types */
export const LOAD_SIMILAR_ITEMS_FAIL = 'la/domain/similarItems/LOAD_FAIL';
export const LOAD_SIMILAR_ITEMS_REQUEST = 'la/domain/similarItems/LOAD_REQUEST';

export const LOAD_SIMILAR_ITEMS_SUCCESS = 'la/domain/similarItems/LOAD_SUCCESS';
export type LOAD_SIMILAR_ITEMS_SUCCESS_ACTION = TypedActionWithPayload<
    typeof LOAD_SIMILAR_ITEMS_SUCCESS,
    ItemSummary[],
    {
        actionTime: number;
        currentPage: number;
        itemId: number;
        totalPages: number;
    }
>;
