export const ADD_LA_PAYMENT_CARD_FAIL = 'la/ui/addLAPaymentCard/FAIL';
export const ADD_LA_PAYMENT_CARD_REQUEST = 'la/ui/addLAPaymentCard/REQUEST';
export const ADD_LA_PAYMENT_CARD_SUCCESS = 'la/ui/addLAPaymentCard/SUCCESS';

export const ADD_ZIP_PAYMENT_CARD_FAIL = 'la/ui/addZipPaymentCard/FAIL';
export const ADD_ZIP_PAYMENT_CARD_REQUEST = 'la/ui/addZipPaymentCard/REQUEST';
export const ADD_ZIP_PAYMENT_CARD_SUCCESS = 'la/ui/addZipPaymentCard/SUCCESS';

export const UPDATE_LA_PAYMENT_CARD_FAIL = 'la/ui/updateLAPaymentCard/FAIL';
export const UPDATE_LA_PAYMENT_CARD_REQUEST = 'la/ui/updateLAPaymentCard/REQUEST';
export const UPDATE_LA_PAYMENT_CARD_SUCCESS = 'la/ui/updateLAPaymentCard/SUCCESS';

export const LOAD_LA_PAYMENT_CARD_FAIL = 'la/ui/loadLAPaymentCard/FAIL';
export const LOAD_LA_PAYMENT_CARD_REQUEST = 'la/ui/loadLAPaymentCard/REQUEST';
export const LOAD_LA_PAYMENT_CARD_SUCCESS = 'la/ui/loadLAPaymentCard/SUCCESS';

export const CLEAR_CARD_ERRORS = 'la/ui/clearCardErrors/SUCCESS';
