import { ApiHosts, handleResponseOld, makeGet } from '../../api/helpers';
import { AuctionCalendarPayload, TodaysCatalogCountApiResponse } from './auctionCalendar.types';

/**
 * @category Item API
 * @see getCatalogsTodayCountApi
 */
type GetCatalogsTodayCountApiParams = {
    deployment: string;
};

/**
 * @category Item API
 * @see getCatalogsTodayCountApi
 */
type GetCatalogsTodayCountApiResponse = {
    data: TodaysCatalogCountApiResponse;
    message: string;
    success: boolean;
};

/**
 * GET request to Item API service for today's catalogs
 * @function fetchCatalogsTodayCountApi
 * @category Item API
 * @param {GetCatalogsTodayCountApiParams}
 * @returns {GetCatalogsTodayCountApiResponse}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/catalogtodaycount
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/get-catalogs-today-count.ts
 */
export const getCatalogsTodayCountApi = ({ deployment }: GetCatalogsTodayCountApiParams) => {
    return new Promise<GetCatalogsTodayCountApiResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/catalogtodaycount',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};

export type SearchFacetValuesOptions = {
    count?: number;
    id?: string;
    name: string;
    qs?: string;
    selected: boolean;
    sequence?: number;
    url?: string;
    value?: string;
};

export type SearchAuctionFacet = {
    count?: number;
    id: string;
    name: string;
    options: [SearchFacetValuesOptions?];
    qs: string;
    role: string;
    selected_date?: string;
    selection: string;
    sequence: number;
    total_count?: number;
    type: string;
    values: [SearchFacetValuesOptions?];
};

export type SearchAuctionResult = {
    catid: number;
    ncatalog_status: string;
    sale_start_ts: number;
};

/**
 * @category Search API
 * @see {getCalendarResultsApi}
 */
type SearchAuctionCalendarResponse = {
    error: boolean;
    payload: AuctionCalendarPayload;
};

/**
 * @category Search API
 * @see getCalendarResultsApi
 */
type GetCalendarResultsApiParams = {
    deployment: string;
    queryParams: any;
};
/**
 * performs GET request to Search service for upcoming calendar auctions
 * @function fetchResultsApi
 * @category Search API
 * @param {GetCalendarResultsApiParams}
 * @returns {SearchAuctionCalendarResponse}
 * @see https://search-party-DEPLOYMENT.liveauctioneers.com/search/catalogsearch
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/p8swwvetjb/resources/vbb3uy/methods/GET
 * @see https://github.com/LIVEauctioneers/search-party/blob/master/functions/AuctionCalendarV2/main.go
 */
export const getCalendarResultsApi = ({ deployment, queryParams }: GetCalendarResultsApiParams) => {
    return new Promise<SearchAuctionCalendarResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'catalogsearch',
            deployment,
            path: ApiHosts.Search,
            queryParams,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
};
