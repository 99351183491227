import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import type { ApiFetchPerformanceStats } from '@/utils/apiPerformanceLogger';

export type PerformanceLog = {
    stats: ApiFetchPerformanceStats;
    url: string;
};

export type ApiPerformanceStatsSlice = {
    client: PerformanceLog;
    server: PerformanceLog;
};

export const defaultApiPerformanceStatsSlice: ApiPerformanceStatsSlice = {
    client: null,
    server: null,
};

export type LogClientPerformanceStatsAction = {
    payload: PerformanceLog;
    type: 'LOG_CLIENT_PERFORMANCE_STATS';
};

export type LogServerPerformanceStatsAction = {
    payload: PerformanceLog;
    type: 'LOG_SERVER_PERFORMANCE_STATS';
};

type Actions = LogClientPerformanceStatsAction | LogServerPerformanceStatsAction;

const reducer = (state = defaultApiPerformanceStatsSlice, action: Actions): ApiPerformanceStatsSlice => {
    switch (action.type) {
        case 'LOG_CLIENT_PERFORMANCE_STATS':
            return {
                ...state,
                client: action.payload,
            };
        case 'LOG_SERVER_PERFORMANCE_STATS':
            return {
                ...state,
                server: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const getApiPerformanceStats = createSelector(stateSelector, (state) => state.apiPerformanceStats);

/* ACTION CREATORS */
export const logClientPerformanceStats = (
    computedApiStats: ApiFetchPerformanceStats,
    location: string
): LogClientPerformanceStatsAction => {
    return {
        payload: { stats: computedApiStats, url: location },
        type: 'LOG_CLIENT_PERFORMANCE_STATS',
    };
};

export const logServerPerformanceStats = (
    computedApiStats: ApiFetchPerformanceStats,
    location: string
): LogServerPerformanceStatsAction => {
    return {
        payload: { stats: computedApiStats, url: location },
        type: 'LOG_SERVER_PERFORMANCE_STATS',
    };
};

/**
 * Returns the entire redux store for performance stats (used by ReduxStoreSizeStats in healthcheck modal)
 */
export const getEntireReduxStore = () => (dispatch: AppDispatch, getState: AppGetState) => {
    return getState();
};
