import { ApiHosts, handleResponseWithNon200Errors, makeGet, makePost } from '@/redux/api/helpers';
import {
    FetchTwoStepAuthenticationParams,
    FetchTwoStepAuthenticationResponse,
    ResendTwoStepAuthenticationCodeParams,
    UpdateTwoStepAuthenticationActivationParams,
    UpdateTwoStepAuthenticationActivationResponse,
} from './twoStepAuthentication.types';

export const getTwoStepAuthenticationActivationStatus = ({
    authToken,
    bidderId,
    deployment,
}: FetchTwoStepAuthenticationParams) => {
    return new Promise<FetchTwoStepAuthenticationResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `mfa/bidders/${bidderId}/mfa/status`,
            authToken,
            deployment,
            path: ApiHosts.Authentication,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
};

export const postActivateTwoStepAuthentication = ({
    authToken,
    bidderId,
    deployment,
}: UpdateTwoStepAuthenticationActivationParams) => {
    return new Promise<UpdateTwoStepAuthenticationActivationResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `mfa/bidders/${bidderId}/enable-mfa`,
            authToken,
            deployment,
            path: ApiHosts.Authentication,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
};

export const postDeactivateTwoStepAuthentication = ({
    authToken,
    bidderId,
    deployment,
}: UpdateTwoStepAuthenticationActivationParams) => {
    return new Promise<UpdateTwoStepAuthenticationActivationResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `mfa/bidders/${bidderId}/disable-mfa`,
            authToken,
            deployment,
            path: ApiHosts.Authentication,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
};

export const postResendTwoStepAuthenticationCode = ({
    deployment,
    emailAddress,
}: ResendTwoStepAuthenticationCodeParams) => {
    return new Promise<void>((resolve, reject) => {
        const request = makePost({
            apiPath: 'mfa/send-otp',
            deployment,
            path: ApiHosts.Authentication,
        });
        request.send({
            username: emailAddress,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
};
