import { BidderProfile } from '@/redux/modules/account/user/user.types';
import { BiddingInfo } from '@/types/BiddingInfo';
import { CatalogRegistrationLegacyServerResponse } from '@/types/CatalogRegistration';
import { getLoginAnalytics } from '@/redux/modules/analytics';
import { Location } from 'react-router-dom';
import { TypedActionWithPayload } from '@/types/redux';
import type { FeatureFlagType } from '@kameleoon/javascript-sdk';

export type LoginSlice = {
    error: boolean;
    errorMessage: string;
    obfuscatedPhoneNumber: string;
    requiresTwoStepAuthentication?: boolean;
    submitted: boolean;
    success: boolean;
};

export const defaultLoginSlice: LoginSlice = {
    error: false,
    errorMessage: '',
    obfuscatedPhoneNumber: '',
    requiresTwoStepAuthentication: undefined,
    submitted: false,
    success: false,
};

/**
 * @deprecated When converting to toolkit, use submitLogin.pending
 */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
/**
 * @deprecated When converting to toolkit, use submitLogin.pending
 */
export type LoginRequestAction = TypedActionWithPayload<typeof LOGIN_REQUEST, string>;

/**
 * @deprecated When converting to toolkit, use submitLogin.fulfilled
 */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
/**
 * @deprecated When converting to toolkit, use submitLogin
 */
export type LoginSuccessAction = TypedActionWithPayload<
    typeof LOGIN_SUCCESS,
    LoginResponsePayload,
    ReturnType<typeof getLoginAnalytics> & { actionTime: number }
>;

/**
 * @deprecated When converting to toolkit, use submitLogin.rejected
 */
export const LOGIN_FAIL = 'LOGIN_FAIL';
/**
 * @deprecated When converting to toolkit, use submitLogin
 */
export type LoginFailAction = TypedActionWithPayload<typeof LOGIN_FAIL, string, { emailAddress: string }>;

/**
 * @category Item API
 * @see postLogin
 */
export type PostLoginParams = {
    deployment: string;
    password: string | null | undefined;
    sessionId: string;
    username: string;
};

/**
 * @category Item API
 * @see getUserByToken
 */
export type GetUserByTokenParams = {
    authToken: string;
    deployment: string;
};

/**
 * @category Item API
 * @see postLogin
 */
export type PostLoginResponse = {
    data: {
        annualNMS: number;
        bidder: BidderProfile;
        biddingInfos: BiddingInfo[];
        catalogRegistration: CatalogRegistrationLegacyServerResponse[];
        followedSellers: number[];
        // mainhostLogin exists on the api but is unused in bidder-react
        mainhostLogin: {
            '5Xq_name': string;
            '5Xq_nuid': string;
            '5Xq_nverify': string;
        };
        resellerType: number;
        savedItems: number[];
        savedItemsTime: {
            itemId: string;
            saveTs: number;
        }[];
        searchExclusions: {
            houseIds: number[];
        };
        token: string;
    };
    message: string;
    success: boolean;
};

export type LoginResponsePayload = Omit<PostLoginResponse['data'], 'annualNMS'> & {
    bidder: PostLoginResponse['data']['bidder'] & {
        ANMS: PostLoginResponse['data']['annualNMS'];
    };
};

export type GetUserByTokenResponsePayload = {
    data: {
        annualNMS: number;
        bidder: BidderProfile;
        biddingInfos: BiddingInfo[];
        catalogRegistration: CatalogRegistrationLegacyServerResponse[];
        followedSellers: number[];
        // mainhostLogin exists on the api but is unused in bidder-react
        mainhostLogin: {
            '5Xq_name': string;
            '5Xq_nuid': string;
            '5Xq_nverify': string;
        };
        resellerType: number;
        savedItems: number[];
        savedItemsTime: {
            itemId: string;
            saveTs: number;
        }[];
        searchExclusions: {
            houseIds: number[];
        };
    };
    message: string;
    success: boolean;
};

export type SubmitLoginInfo = {
    emailAddress: string | null | undefined;
    featureFlags: FeatureFlagType[];
    location: Location;
    newAccount: boolean;
    onLogin?: () => void;
    password: string | null | undefined;
    shouldCloseModal: boolean;
    twoStepAuthenticationCode?: string;
};

export type SubmitTokenLoginInfo = {
    authToken: string;
    featureFlags: FeatureFlagType[];
    location: Location;
    onLogin?: () => void;
    shouldCloseModal: boolean;
};

export type PostTwoStepAuthenticationLoginParams = PostLoginParams & {
    twoStepAuthenticationCode: string;
};

export enum CodeDeliveryMethod {
    Email = 'email',
    Sms = 'sms',
}
