import { createSlice } from '@reduxjs/toolkit';
import {
    defaultDeleteAccountSlice,
    DeleteAccountSlice,
} from '@/redux/modules/account/deleteAccount/deleteAccount.types';
import {
    resetAccountDeleteSlice,
    submitDeleteAccount,
} from '@/redux/modules/account/deleteAccount/deleteAccount.actions';

const successfulAccountDeletionReducer = (slice: DeleteAccountSlice) => ({
    ...slice,
    error: null,
    submitted: false,
    success: true,
});

const deleteAccountSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(submitDeleteAccount.pending, (slice) => {
            slice.error = null;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(submitDeleteAccount.fulfilled, successfulAccountDeletionReducer);
        builder.addCase(submitDeleteAccount.rejected, (slice, { payload }) => {
            slice.error = payload;
            slice.submitted = false;
            slice.success = false;
        });

        builder.addCase(resetAccountDeleteSlice.fulfilled, successfulAccountDeletionReducer);
    },
    initialState: defaultDeleteAccountSlice,
    name: 'deleteAccount',
    reducers: {},
});

export const { reducer: deleteAccountReducer } = deleteAccountSlice;
