import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getIsWhiteLabel } from './whiteLabel';
import { routes as getRoutes } from '@/routes';
import { KameleoonExperimentsVariantIds } from '@/hooks/kameleoon/kameleoonExperiments';
import { Location, matchRoutes } from 'react-router-dom';
import type { FeatureFlagType } from '@kameleoon/javascript-sdk';

export const loadPageData = createAsyncThunk<
    Promise<unknown>[],
    {
        featureFlagVariants?: KameleoonExperimentsVariantIds;
        featureFlags: FeatureFlagType[];
        location: Location;
    }
>('la/ui/appState/loadPageData', async ({ featureFlags, featureFlagVariants, location }, { dispatch, getState }) => {
    const state = getState();

    // Need to know if the app is working as whitelabel
    const isWhiteLabel = getIsWhiteLabel(state);

    // Get application routes
    const routes = getRoutes({ featureFlagVariants, isWhiteLabel });

    // Get all the currently active matching branch routes
    const branches = matchRoutes(routes, location.pathname);

    // Make an array of promises for the page data
    const promises = branches.map((branch) => {
        const { params, route } = branch;
        if (route.loadData) {
            return route.loadData({
                featureFlags,
                featureFlagVariants,
                location,
                match: { params: params },
                routerState: {},
                store: {
                    dispatch,
                    getState,
                },
            });
        }

        return Promise.resolve();
    });

    return Promise.all(promises);
});
