import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { FetchBidderDataResponse } from '@/redux/modules/account/user/user.types';

/**
 * @category Item API
 * @see getBidderData
 */
type FetchBidderDataParams = {
    authToken: string;
    deployment: string;
};

/**
 * GET request to item-api to load Bidder data
 * @function fetchBidderData
 * @category Item API
 * @param {FetchBidderDataParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/auth/spauser
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-user.ts
 */
export const getBidderData = ({ authToken, deployment }: FetchBidderDataParams) =>
    new Promise<FetchBidderDataResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'auth/spauser',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
            })
        );
    });
