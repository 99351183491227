import { createSlice } from '@reduxjs/toolkit';
import { defaultLoginSlice, LoginSlice } from './login.types';
import { fetchObfuscatedPhoneNumber, submitLogin, submitLoginWithToken } from './login.actions';
import { OPEN_LOGIN_MODAL } from '@/redux/modules/actions';
import { submitCreateAccount } from '@/redux/modules/account/createAccount/createAccount.actions';

const resetLoginSliceReducer = (): LoginSlice => defaultLoginSlice;

const loginSlice = createSlice({
    extraReducers: (builder) => {
        // spaLogin
        builder.addCase(submitLogin.pending, (slice) => {
            slice.error = false;
            slice.errorMessage = '';
            slice.requiresTwoStepAuthentication = undefined;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(submitLogin.fulfilled, (slice) => {
            slice.error = false;
            slice.errorMessage = '';
            slice.requiresTwoStepAuthentication = false;
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(submitLogin.rejected, (slice, { error }) => {
            slice.submitted = false;
            slice.success = false;
            if (error.message.toLowerCase() === 'MFA is required'.toLowerCase()) {
                slice.requiresTwoStepAuthentication = true;
            } else {
                const shouldProtectAccount = error.message === 'Failed to authenticate a';
                slice.error = true;
                slice.errorMessage = shouldProtectAccount ? 'protect' : error.message;
            }
        });

        // submitLoginWithToken
        builder.addCase(submitLoginWithToken.pending, (slice) => {
            slice.error = false;
            slice.errorMessage = '';
            slice.requiresTwoStepAuthentication = undefined;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(submitLoginWithToken.fulfilled, (slice) => {
            slice.error = false;
            slice.errorMessage = '';
            slice.requiresTwoStepAuthentication = false;
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(submitLoginWithToken.rejected, (slice, { error }) => {
            slice.submitted = false;
            slice.success = false;

            const shouldProtectAccount = error.message === 'Failed to authenticate a';
            slice.error = true;
            slice.errorMessage = shouldProtectAccount ? 'protect' : error.message;
        });

        builder.addCase<typeof OPEN_LOGIN_MODAL, { type: typeof OPEN_LOGIN_MODAL }>(
            OPEN_LOGIN_MODAL,
            resetLoginSliceReducer
        );

        builder.addCase(fetchObfuscatedPhoneNumber.fulfilled, (slice, { payload }) => {
            slice.obfuscatedPhoneNumber = payload;
        });
        builder.addCase(fetchObfuscatedPhoneNumber.rejected, (slice, { error }) => {
            slice.error = true;
            slice.errorMessage = error.message;
        });

        builder.addCase(submitCreateAccount.rejected, (slice, { payload }) => {
            if (payload.message.toLowerCase().includes('user already exists')) {
                slice.error = true;
                slice.errorMessage = payload.message;
            }
        });
    },
    initialState: defaultLoginSlice,
    name: 'login',
    reducers: {
        resetLoginSlice: resetLoginSliceReducer,
    },
});

export const { reducer: loginReducer } = loginSlice;
export const { resetLoginSlice } = loginSlice.actions;
