import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const auctionCalendarSlice = createSelector(stateSelector, (state) => state.auctionCalendar);

export const getCatalogsTodayCount = createSelector(auctionCalendarSlice, (state) => state.catalogsTodayCount);

export const getAuctionCalendarIds = createSelector(auctionCalendarSlice, (state) => state.catalogIds);

export const getAuctionCalendarTotalCount = createSelector(auctionCalendarSlice, (state) => state.totalFound);

export const getAuctionCalendarSubmitted = createSelector(auctionCalendarSlice, (state) => state.submitted);

export const getAuctionCalendarFacets = createSelector(auctionCalendarSlice, (state) =>
    state.facets.length > 0 ? state.facets : []
);

export const getAuctionCalendarHouseFacets = createSelector(getAuctionCalendarFacets, (facets) => {
    const facet = facets.find((x) => x.id === 'house_id');
    if (Boolean(facet?.values?.length)) {
        return [...facet.values].sort(sortFacets);
    }
    return [];
});

export const getAuctionCalendarCountryFacets = createSelector(getAuctionCalendarFacets, (facets) => {
    const facet = facets.find((x) => x.id === 'country');
    if (Boolean(facet?.values?.length)) {
        return [...facet.values].sort(sortFacets);
    }
    return [];
});

export const getAuctionCalendarLiveBroadcastFacets = createSelector(getAuctionCalendarFacets, (facets) => {
    const facet = facets.find((x) => x.id === 'audioVideo');
    if (Boolean(facet?.values?.length)) {
        return [...facet.values].sort(sortFacets);
    }
    return [];
});

export const getAuctionCalendarShippingFacets = createSelector(getAuctionCalendarFacets, (facets) => {
    const facet = facets.find((x) => x.id === 'shipping');
    if (Boolean(facet?.values?.length)) {
        return [...facet.values].sort(sortFacets);
    }
    return [];
});

export const getAuctionCalendarSaleTypeFacets = createSelector(getAuctionCalendarFacets, (facets) => {
    const facet = facets.find((x) => x.id === 'sale_type');
    if (Boolean(facet?.values?.length)) {
        return [...facet.values].sort(sortFacets);
    }
    return [];
});

export const getAuctionCalendarTopRated = createSelector(getAuctionCalendarFacets, (facets) => {
    const topRated = facets.find((x) => x.id === 'topRated');
    const topRatedValue = topRated?.options?.find((x) => x.id === 'topRated');
    return Boolean(topRatedValue) ? [topRatedValue] : [];
});

const sortFacets = (a, b) => {
    if (a.selected === b.selected) {
        return b.count - a.count;
    }
    return b.selected ? 1 : -1;
};
