export type TwoStepAuthenticationSlice = {
    activated?: boolean;
    error?: string;
    loaded: boolean;
};

export const defaultTwoStepAuthenticationSlice: TwoStepAuthenticationSlice = {
    activated: undefined,
    error: undefined,
    loaded: false,
};

export type TwoStepAuthenticationResponsePayload = {
    mfaEnabled: boolean;
    success: boolean;
};

export type FetchTwoStepAuthenticationParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

export type FetchTwoStepAuthenticationResponse = {
    error: boolean;
    payload: TwoStepAuthenticationResponsePayload | string;
};

export type UpdateTwoStepAuthenticationActivationParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

export type UpdateTwoStepAuthenticationActivationResponse = {
    error: boolean;
    payload: TwoStepAuthenticationResponsePayload | string;
};

export type ResendTwoStepAuthenticationCodeParams = {
    deployment: string;
    emailAddress: string;
};

export type ActivateTwoStepAuthenticationPayload = {
    bidderId: number;
    populatedNewAccountAuthToken?: string;
};
