import { COMPLETE_ACCOUNT_SUCCESS } from '../../actions';
import { CompleteAccountSuccessAction } from '@/redux/modules/completeAccount';
import { createSlice } from '@reduxjs/toolkit';
import { defaultUserSlice, emptyUserData, UserSlice } from '@/redux/modules/account/user/user.types';
import { fetchBidderData, storeSavedAddress } from '@/redux/modules/account/user/user.actions';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { submitConfirmPassword } from '../../confirmPassword/confirmPassword.actions';
import { submitConsent } from '../../consent/consent.actions';
import { submitCreateAccount } from '../createAccount/createAccount.actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';

const beginLoadingReducer = (slice: UserSlice): UserSlice => ({
    ...slice,
    loading: true,
});

type AuthTokenBidderDataAction =
    | FulfilledActionFromAsyncThunk<typeof submitLogin>
    | FulfilledActionFromAsyncThunk<typeof submitLoginWithToken>
    | FulfilledActionFromAsyncThunk<typeof submitConfirmPassword>;

type PopulateBidderDataAction = AuthTokenBidderDataAction | FulfilledActionFromAsyncThunk<typeof fetchBidderData>;

const populateBidderDataReducer = (slice: UserSlice, { payload }: PopulateBidderDataAction): UserSlice => ({
    ...slice,
    loaded: Date.now(),
    loading: false,
    userData: {
        ...emptyUserData,
        ...payload.bidder,
        // @ts-expect-error
        // submitLogin and fetchBidderData handle ANMS very slightly differently
        // In order to reduce duplicate code, this is brought over
        // from the non-toolkit implementation of the reducer
        ANMS: payload.annualNMS ?? payload.bidder.ANMS ?? 0,
        upcomingBids: payload.biddingInfos.length || slice.userData.upcomingBids || 0,
    },
});

const populateBidderDataWithTokenReducer = (slice: UserSlice, action: AuthTokenBidderDataAction): UserSlice => ({
    ...populateBidderDataReducer(slice, action),
    token: action.payload.token,
});

const stopLoadingReducer = (slice: UserSlice): UserSlice => ({
    ...slice,
    loading: false,
});

const logOutUserReducer = (): UserSlice => defaultUserSlice;

const userSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchBidderData.pending, beginLoadingReducer);
        builder.addCase(fetchBidderData.fulfilled, populateBidderDataReducer);
        builder.addCase(fetchBidderData.rejected, stopLoadingReducer);

        builder.addCase(submitLogin.pending, beginLoadingReducer);
        builder.addCase(submitLogin.fulfilled, populateBidderDataWithTokenReducer);
        builder.addCase(submitLogin.rejected, logOutUserReducer);

        builder.addCase(submitLoginWithToken.pending, beginLoadingReducer);
        builder.addCase(submitLoginWithToken.fulfilled, populateBidderDataWithTokenReducer);
        builder.addCase(submitLoginWithToken.rejected, logOutUserReducer);

        builder.addCase(submitConfirmPassword.fulfilled, populateBidderDataWithTokenReducer);

        builder.addCase(submitCreateAccount.pending, beginLoadingReducer);
        builder.addCase(submitCreateAccount.fulfilled, (slice, { payload }) => {
            slice.loaded = Date.now();
            slice.loading = false;
            slice.token = payload;
        });
        builder.addCase(submitCreateAccount.rejected, logOutUserReducer);

        builder.addCase<typeof COMPLETE_ACCOUNT_SUCCESS, CompleteAccountSuccessAction>(
            COMPLETE_ACCOUNT_SUCCESS,
            (slice, { payload }) => {
                slice.loaded = Date.now();
                slice.loading = false;
                slice.userData = {
                    ...(slice.userData || emptyUserData),
                    ...payload,
                };
            }
        );

        builder.addCase(submitConsent.fulfilled, (slice, { payload }) => {
            slice.userData = {
                ...slice.userData,
                acceptedTermsOfService: payload,
            };
        });

        builder.addCase(storeSavedAddress.fulfilled, (slice, { payload }) => {
            slice.userData = {
                ...slice.userData,
                ...payload,
            };
        });

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(LOG_OUT_BIDDER, logOutUserReducer);
    },
    initialState: defaultUserSlice,
    name: 'user',
    reducers: {},
});

export const { reducer: userReducer } = userSlice;
