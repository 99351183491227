import { ApprovalStatus } from '@/types/Approval';
import { getRequestedAuctionRegistrationAnalytics } from '@/redux/modules/analytics';
import { TypedActionWithPayload } from '@/types/redux';

/* Action Types */
export const REGISTER_FOR_CATALOG_FAIL = 'la/ui/registerForCatalog/FAIL';
export type RegisterForCatalogFailAction = TypedActionWithPayload<
    typeof REGISTER_FOR_CATALOG_FAIL,
    string,
    { catalogId: number }
>;

export const REGISTER_FOR_CATALOG_REQUEST = 'la/ui/registerForCatalog/REQUEST';
export type RegisterForCatalogRequestAction = TypedActionWithPayload<typeof REGISTER_FOR_CATALOG_REQUEST>;

export const REGISTER_FOR_CATALOG_SUCCESS = 'la/ui/registerForCatalog/SUCCESS';
export type RegisterForCatalogSuccessAction = TypedActionWithPayload<
    typeof REGISTER_FOR_CATALOG_SUCCESS,
    {
        approvalStatus: ApprovalStatus;
        bidLimit: number;
        bidderId: number;
        catalogId: number;
    },
    ReturnType<typeof getRequestedAuctionRegistrationAnalytics>
>;

export const CARD_REAUTH_FAILURE = 'la/ui/registerForCatalog/CARD_REAUTH_FAILURE';
export const CARD_REAUTH_REQUEST = 'la/ui/registerForCatalog/CARD_REAUTH_REQUEST';
export const CARD_REAUTH_SUCCESS = 'la/ui/registerForCatalog/CARD_REAUTH_SUCCESS';

/**
 * @deprecated When converting to toolkit, use loadLiveCatalogRegistrationById.fulfilled
 */
export const LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS = 'la/domain/liveCatalogRegistration/LOAD_SUCCESS';

/**
 * @deprecated When converting to toolkit, use fetchLiveCatalogRegistrationByIdIfNeeded/loadLiveCatalogRegistrationById
 */
export type LoadLiveCatalogRegistrationSuccessAction = TypedActionWithPayload<
    typeof LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS,
    {
        approvalStatus: ApprovalStatus;
        approved: boolean;
        bidLimit: number;
        catalogId: number;
        created: number;
        wasApproved: boolean;
    },
    {
        actionTime: number;
        catalogId: number;
    }
>;
