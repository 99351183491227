import { createSelector } from '@reduxjs/toolkit';
import { differenceInDays, parseISO } from 'date-fns';
import { getOpenModal } from '@/redux/modules/modal.selectors';
import { GlobalState } from '@/redux/store';
import { modalTypes } from '@/enums';

export const DAYS_TO_WAIT_TO_SHOW_MODAL_AGAIN = 7;

const stateSelector = (state: GlobalState) => state;
const autoPopModalSlice = createSelector(stateSelector, (state) => state.autoPopModal);

export const getUiDateLastShown = createSelector(autoPopModalSlice, (state) => state.dateLastShown);
export const getUiHasLoggedInBefore = createSelector(autoPopModalSlice, (state) => state.hasLoggedInBefore);
export const getUiModalShown = createSelector(getUiDateLastShown, (date) => Boolean(date));
export const shouldStopAutoPopup = createSelector(autoPopModalSlice, (state) => state.disableModalForTesting);

export const shouldPopModal = createSelector(
    [shouldStopAutoPopup, getOpenModal, getUiDateLastShown, getUiModalShown, getUiHasLoggedInBefore],
    (abort, openModal, dateLastShown, hasSeenModal, hasLoggedInBefore) => {
        if (abort) {
            return false;
        }

        let notSeenRecently = false;
        if (Boolean(dateLastShown)) {
            const parsedDateLastShown = parseISO(dateLastShown);
            const difference = differenceInDays(new Date(), parsedDateLastShown);
            notSeenRecently = difference >= DAYS_TO_WAIT_TO_SHOW_MODAL_AGAIN;
        }
        return (!hasSeenModal || notSeenRecently) && !hasLoggedInBefore && openModal === modalTypes.NONE;
    }
);
