import {
    AbsenteeBidDetails,
    SubmitAbsenteeBidParams,
    SubmitAbsenteeBidPayload,
} from '@/redux/modules/absenteeBid/absenteeBid.types';
import {
    AbsenteeBidFlowStep,
    getPlacedAbsenteeBidAnalytics,
    trackConversionForAlgolia,
} from '@/redux/modules/analytics';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchAllBidderBiddingInfoIfNeeded } from '@/redux/modules/bidder/biddingInfoAll/bidderBiddingInfoAll.actions';
import { fetchBidLimitBalanceIfNeeded } from '@/redux/modules/bidLimit';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { isUiMobile } from '@/redux/modules/browser';
import { postPlaceAbsenteeBid } from './absenteeBid.api';

export const submitAbsenteeBid = createAsyncThunk<SubmitAbsenteeBidPayload, SubmitAbsenteeBidParams>(
    'la/ui/absenteeBid/submitAbsenteeBid',
    async ({ absenteeBidDetails, location }, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);

        const isMobile = isUiMobile(state);
        const details: AbsenteeBidDetails = {
            ...absenteeBidDetails,
            client: 'React',
            deviceType: isMobile ? 'Mobile' : 'Desktop',
        };

        const response = await postPlaceAbsenteeBid({
            authToken,
            deployment,
            details: details,
        });

        const step = details.raisedAbsenteeBid
            ? AbsenteeBidFlowStep.raisedAbsenteeBid
            : AbsenteeBidFlowStep.placedAbsenteeBid;

        const analytics = getPlacedAbsenteeBidAnalytics(state, details.itemId, details.bidAmount, step, location);
        await dispatch(trackConversionForAlgolia(details.itemId));

        const fetchBidLimitBalance = await dispatch(
            fetchBidLimitBalanceIfNeeded({
                catalogId: details.catalogId,
                force: true,
                itemId: details.itemId,
            })
        );
        const fetchAllBidderBidding = await dispatch(fetchAllBidderBiddingInfoIfNeeded(true));
        await Promise.allSettled([fetchBidLimitBalance, fetchAllBidderBidding]);

        return {
            meta: {
                actionTime: Date.now(),
                analytics,
                itemId: details.itemId,
            },
            payload: response,
        };
    }
);
