import { createSlice } from '@reduxjs/toolkit';
import { defaultAbsenteeBidSlice } from './absenteeBid.types';
import {
    DISMISS_MODAL,
    DISMISS_MODAL_ACTION,
    OPEN_PLACE_ABSENTEE_BID,
    OpenAbsenteeBidModalAction,
} from '@/redux/modules/actions';
import { submitAbsenteeBid } from './absenteeBid.actions';

const absenteeBidSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(submitAbsenteeBid.pending, (slice) => {
            slice.bidPlaced = true;
            slice.error = false;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(submitAbsenteeBid.fulfilled, (slice, { payload }) => {
            slice.bidAmount = null;
            slice.error = false;
            slice.isCurrentHighBid = payload.payload.isCurrentHighBid;
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(submitAbsenteeBid.rejected, (slice) => {
            slice.bidPlaced = false;
            slice.error = true;
            slice.submitted = false;
            slice.success = false;
        });

        builder.addCase<typeof DISMISS_MODAL, DISMISS_MODAL_ACTION>(DISMISS_MODAL, (slice) => {
            slice.bidPlaced = false;
        });
        builder.addCase<typeof OPEN_PLACE_ABSENTEE_BID, OpenAbsenteeBidModalAction>(
            OPEN_PLACE_ABSENTEE_BID,
            (slice, { payload }) => {
                slice.bidAmount = payload.bidAmount || null;
                slice.itemId = payload.itemId;
            }
        );
    },
    initialState: defaultAbsenteeBidSlice,
    name: 'absenteeBid',
    reducers: {},
});

export const { reducer: absenteeBidReducer } = absenteeBidSlice;
