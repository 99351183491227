export type ShouldLoginSlice = {
    completeAccountModalOpen: boolean;
    startTracking: boolean;
    trigger: string;
};

export const defaultShouldLoginSlice: ShouldLoginSlice = {
    completeAccountModalOpen: false,
    startTracking: false,
    trigger: '',
};
