import { ApiHosts, handleResponseOld, makePost } from '@/redux/api/helpers';

/**
 * @category User API
 * @see postCreateAccount
 */
type PostCreateAccountResponse = {
    error: boolean;
    payload: {
        bidderExists?: boolean; // TODO: not sure if this is being used at all
        bidderId: number;
        createToken: string;
    };
};

/**
 * @category User API
 * @see postCreateAccount
 */
type PostCreateAccountParams = {
    agree: any;
    deployment: string;
    deviceId: string | undefined;
    emailAddress: string;
    pageName: string;
    sessionId: string | undefined;
    url: string;
    visitorCode: string;
};

/**
 * POST to Flynn user API service to create a new user account
 * @function postCreateAccount
 * @category User API
 * @param {PostCreateAccountParams}
 * @see https://api.liveauctioneers.com/user/createuser/DEPLOYMENT
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/nci812/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnCreateBidderV2
 */
export const postCreateAccount = ({
    agree,
    deployment,
    deviceId,
    emailAddress,
    pageName,
    sessionId,
    url,
    visitorCode,
}: PostCreateAccountParams) =>
    new Promise<PostCreateAccountResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'createuser',
            deployment,
            path: ApiHosts.FlynnUser,
        });
        const requestBody: any = {
            acceptedTermsOfService: agree,
            client_version: '4.3',
            deviceId,
            email_address: emailAddress,
            pageName,
            sessionId,
            signup_client: 'web',
            url,
            visitorCode,
        };

        request.send(requestBody);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category User API
 * @see postSetPassword
 */
type PostSetPasswordResponse = {
    error: boolean;
    payload: string;
};

/**
 * @category User API
 * @see postSetPassword
 */
type PostSetPasswordParams = {
    authToken: string;
    deployment: string;
    password: string;
};

/**
 * POST to Flynn User API service to create a password for a user account
 * @function postSetPassword
 * @category User API
 * @param {PostSetPasswordParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/createpassword
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/63czot/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnCreatePasswordV2
 */
export const postSetPassword = ({ authToken, deployment, password }: PostSetPasswordParams) =>
    new Promise<PostSetPasswordResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'createpassword',
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({ password });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
